// sagas/orderListSaga.js
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  FETCH_ORDER_LIST_REQUEST,
  fetchOrderListSuccess,
  fetchOrderListFailure
} from '../actions/fetchOrderListAction';
import { getRequest } from 'api/apiCall';
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

function* fetchOrderListSaga(action) {
  try {
    const data = yield call(getRequest, action.payload.url);
    yield put(fetchOrderListSuccess(data));
  } catch (error) {
    captureCustomSentryEvent("fetchOrderList", error);
    yield put(fetchOrderListFailure(error.message));
  }
}

function* orderListSaga() {
  yield takeEvery(FETCH_ORDER_LIST_REQUEST, fetchOrderListSaga);
}

export default orderListSaga;
