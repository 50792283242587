export const FETCH_IR_DETAILS_REQUEST = 'FETCH_IR_DETAILS_REQUEST';
export const FETCH_IR_DETAILS_SUCCESS = 'FETCH_IR_DETAILS_SUCCESS';
export const FETCH_IR_DETAILS_FAILURE = 'FETCH_IR_DETAILS_FAILURE';

export const fetchIRDetailsRequest = (page) => ({
  type: FETCH_IR_DETAILS_REQUEST,
  payload: { page }
});

export const fetchIRDetailsSuccess = (data) => ({
  type: FETCH_IR_DETAILS_SUCCESS,
  payload: { data },
});

export const fetchIRDetailsFailure = (error) => ({
  type: FETCH_IR_DETAILS_FAILURE,
  payload: { error },
});