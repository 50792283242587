// reducers/catalogPriceReducer.js

import {
    FETCH_CATALOG_PRICE_REQUEST,
    FETCH_CATALOG_PRICE_SUCCESS,
    FETCH_CATALOG_PRICE_FAILURE,
  } from '../actions/catalogFilterActions';
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
  };
  
  const catalogPriceFilterReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CATALOG_PRICE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_CATALOG_PRICE_SUCCESS:
        return {
          ...state,
          data: action.payload.data,
          loading: false,
          error: null,
        };
      case FETCH_CATALOG_PRICE_FAILURE:
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload.error,
        };
      default:
        return state;
    }
  };
  
  export default catalogPriceFilterReducer;
  