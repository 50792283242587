// material-ui
// import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  // const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="BTTN" width="100" />
     *
     */
    <svg width="126" height="36" viewBox="0 0 126 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M43.8149 31.9297V35.5874H39.5584V0.124093H43.8149V13.9483C45.4188 11.5936 48.7229 9.94002 52.0796 9.94002C59.8945 9.94002 64.2006 15.5491 64.2006 22.9638C64.2006 30.3785 59.8945 35.9876 52.03 35.9876C48.3227 35.9876 44.969 34.2347 43.8149 31.9297ZM51.6298 32.181C56.5874 32.181 59.8449 28.5233 59.8449 22.9638C59.8449 17.4043 56.5905 13.7466 51.6298 13.7466C46.6691 13.7466 43.4147 17.4043 43.4147 22.9638C43.4147 28.5233 46.6691 32.181 51.6298 32.181Z" fill="white"/>
<path d="M77.4974 35.9876C73.1913 35.9876 70.0331 33.5832 70.0331 28.0238V14.1003H65.3237V10.3433H70.0331V2.68045H74.2896V10.3433H81.0527V14.1003H74.2896V28.1758C74.2896 30.8314 75.7911 32.1313 77.9969 32.1313C78.999 32.1313 80.1499 31.9824 81.152 31.4798L81.4529 35.3857C80.1996 35.8356 78.847 35.9876 77.4974 35.9876Z" fill="white"/>
<path d="M95.879 35.9876C91.5698 35.9876 88.4147 33.5832 88.4147 28.0238V14.1003H83.7053V10.3433H88.4147V2.68045H92.6712V10.3433H99.4343V14.1003H92.6712V28.1758C92.6712 30.8314 94.1727 32.1313 96.3785 32.1313C97.3806 32.1313 98.5315 31.9824 99.5336 31.4798L99.8345 35.3857C98.5812 35.8356 97.2285 35.9876 95.879 35.9876Z" fill="white"/>
<path d="M103.514 10.3433H107.77V15.1024C109.523 12.0962 112.579 9.94312 116.435 9.94312C121.694 9.94312 125.451 13.4984 125.451 18.8594V35.5874H121.194V19.6598C121.194 16.2534 119.041 13.7994 115.486 13.7994C110.777 13.7994 107.774 17.8573 107.774 24.5181V35.5874H103.517V10.3433H103.514Z" fill="white"/>
<path d="M18 0C8.05998 0 0 8.05998 0 18C0 27.94 8.05998 36 18 36C27.94 36 36 27.94 36 18C36 8.05998 27.94 0 18 0ZM11.1282 20.5874C9.61737 20.5874 8.39193 19.3619 8.39193 17.8511C8.39193 16.3402 9.61737 15.1148 11.1282 15.1148C12.6391 15.1148 13.8645 16.3402 13.8645 17.8511C13.8645 19.3619 12.6391 20.5874 11.1282 20.5874ZM17.9473 8.8697C19.4581 8.8697 20.6836 10.0951 20.6836 11.606C20.6836 13.1169 19.4581 14.3423 17.9473 14.3423C16.4364 14.3423 15.211 13.1169 15.211 11.606C15.211 10.0951 16.4364 8.8697 17.9473 8.8697ZM18.031 27.5926C16.5202 27.5926 15.2947 26.3671 15.2947 24.8563C15.2947 23.3454 16.5202 22.12 18.031 22.12C19.5419 22.12 20.7673 23.3454 20.7673 24.8563C20.7673 26.3671 19.5419 27.5926 18.031 27.5926ZM24.7694 20.5874C23.2585 20.5874 22.0331 19.3619 22.0331 17.8511C22.0331 16.3402 23.2585 15.1148 24.7694 15.1148C26.2802 15.1148 27.5057 16.3402 27.5057 17.8511C27.5057 19.3619 26.2802 20.5874 24.7694 20.5874Z" fill="white"/>
</svg>

  );
};

export default Logo;
