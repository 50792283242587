import { call, put, takeLatest } from "redux-saga/effects";
import { getRequest } from "api/apiCall";
import {
  FETCH_ORDER_DETAILS_REQUEST,
  fetchOrderDetailsSuccess,
  fetchOrderDetailsFailure,
} from "../actions/fetchOrderDetailsActions";
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

function* fetchOrderDetails(action) {
  try {
    const response = yield call(
      getRequest,
      `/customers/order-details?order_id=${action.payload.orderId}&page=${action.payload.page}&page_size=20`
    );
    yield put(fetchOrderDetailsSuccess(response));
  } catch (error) {
    captureCustomSentryEvent("fetchOrderDetails", error);
    yield put(fetchOrderDetailsFailure(error.message));
  }
}

export function* fetchOrderDetailsSaga() {
  yield takeLatest(FETCH_ORDER_DETAILS_REQUEST, fetchOrderDetails);
}

export default fetchOrderDetailsSaga;
