// Action Types
export const FETCH_DROP_DOWN_COMPANIES_REQUEST = "FETCH_DROP_DOWN_COMPANIES_REQUEST";
export const FETCH_DROP_DOWN_COMPANIES_SUCCESS = "FETCH_DROP_DOWN_COMPANIES_SUCCESS";
export const FETCH_DROP_DOWN_COMPANIES_FAILURE = "FETCH_DROP_DOWN_COMPANIES_FAILURE";
export const FETCH_DROP_DOWN_COMPANIES_RESET = "FETCH_DROP_DOWN_COMPANIES_RESET"

// Action Creators
export const fetchDropDownCompaniesRequest = (page) => ({
  type: FETCH_DROP_DOWN_COMPANIES_REQUEST,
  payload:page
});

export const fetchDropDownCompaniesSuccess = (companies) => ({
  type: FETCH_DROP_DOWN_COMPANIES_SUCCESS,
  payload: companies,
});

export const fetchDropDownCompaniesFailure = (error) => ({
  type: FETCH_DROP_DOWN_COMPANIES_FAILURE,
  payload: error,
});


export const fetchDropDownCompaniesReset = () => ({
  type: FETCH_DROP_DOWN_COMPANIES_RESET,

});