import { takeLatest, call, put } from "redux-saga/effects";
import {
  FETCH_SHOPPING_LIST_REQUEST,
  fetchShoppingListSuccess,
  fetchShoppingListFailure,
} from "../actions/shoppingListActions";
import { getRequest } from "api/apiCall";
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

function* fetchShoppingList(action) {
  try {
    const { companyId } = action.payload;
    const url = `/shopping-lists/shopping-list?company_id=${companyId}`;

    const response = yield call(getRequest, url);

    yield put(fetchShoppingListSuccess(response));
  } catch (error) {
    captureCustomSentryEvent("fetchShoppingList", error);
    yield put(fetchShoppingListFailure(error));
  }
}

function* shoppingListSaga() {
  yield takeLatest(FETCH_SHOPPING_LIST_REQUEST, fetchShoppingList);
}

export default shoppingListSaga;