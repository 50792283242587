// actions.js

// Action Types
export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';
export const FETCH_LOGIN_REQUEST_SUCCESS = 'FETCH_LOGIN_REQUEST_SUCCESS';
export const FETCH_LOGIN_REQUEST_FAILURE = 'FETCH_LOGIN_REQUEST_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';
export const ADD_PENDING_REQUEST = 'ADD_PENDING_REQUEST';
export const REMOVE_PENDING_REQUEST = 'REMOVE_PENDING_REQUEST';

// Action Creators
export const fetchLoginRequest = (username,password) => ({
  type: FETCH_LOGIN_REQUEST,
  payload: {
    username:username,
    password:password
  },
});

export const fetchLoginRequestSuccess = (data) => ({
  type: FETCH_LOGIN_REQUEST_SUCCESS,
  payload: {data},
});

export const fetchLoginRequestFailure = (error) => ({
  type: FETCH_LOGIN_REQUEST_FAILURE,
  payload:{ error},
});

export const refreshTokenRequest = () => ({
  type: REFRESH_TOKEN_REQUEST,
});

export const refreshTokenSuccess = (data) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: { data },
});

export const refreshTokenFailure = (error) => ({
  type: REFRESH_TOKEN_FAILURE,
  payload: { error },
});

export const addPendingRequest = (requestConfig) => ({
  type: ADD_PENDING_REQUEST,
  payload: { requestConfig },
});

export const removePendingRequest = (requestConfig) => ({
  type: REMOVE_PENDING_REQUEST,
  payload: { requestConfig },
});
