// reducers/notificationReducer.js

import {
    FETCH_NOTIFICATION_REQUEST,
    FETCH_NOTIFICATION_SUCCESS,
    FETCH_NOTIFICATION_FAILURE,
} from '../actions/fetchNotificationActions';

const initialState = {
    data: {},
    loading: false,
    error: null,
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_NOTIFICATION_SUCCESS:
            return {
                ...state,
                data: {...state.data,...action.payload.data},
                loading: false,
                error: null,
            };
        case FETCH_NOTIFICATION_FAILURE:
            return {
                ...state,
                data: {},
                loading: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
};

export default notificationReducer;
