// reducers.js

import {
    FETCH_PRICE_LIST_REQUEST,
    FETCH_PRICE_LIST_SUCCESS,
    FETCH_PRICE_LIST_FAILURE,
  } from '../actions/fetchPriceListAction';
  
  const initialState = {
    data:[],
    loading: false,
    priceList: null,
    error: null,
  };
  
  const priceListReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PRICE_LIST_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_PRICE_LIST_SUCCESS:
        return {
          ...state,
          data:action.payload.data,
          loading: false,
          priceList: action.payload,
          error: null,
        };
      case FETCH_PRICE_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          priceList: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default priceListReducer;
  