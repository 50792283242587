// Import action types from your actions file
import {
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_COMPANY_LIST_EMPTY
} from "../actions/fetchCompanysListActions";

// Initial state for the order list reducer
const initialState = {
  data: [],
  loading: false,
  error: null,
  hasNextPage: false,
  page:0
};

// Reducer function for order list
const companiesListReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      case FETCH_COMPANY_LIST_EMPTY:
        return {
          ...state,
          data:[]
        };  
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        data:[...state.data,...action.payload.data.data.data],
        page:action.payload.data.data.count,

        loading: false,
        error: null,
      };

    case FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default companiesListReducer;
