export const FETCH_SHOPPING_LIST_ITEMS_REQUEST = "FETCH_SHOPPING_LIST_ITEMS_REQUEST";
export const FETCH_SHOPPING_LIST_ITEMS_SUCCESS = "FETCH_SHOPPING_LIST_ITEMS_SUCCESS";
export const FETCH_SHOPPING_LIST_ITEMS_FAILURE = "FETCH_SHOPPING_LIST_ITEMS_FAILURE";

export const fetchShoppingListItems = (shoppingListId, companyId,page, searchText) => ({
    type: FETCH_SHOPPING_LIST_ITEMS_REQUEST,
    payload: { shoppingListId, companyId ,page, searchText}
  });

  export const fetchShoppingListItemsSuccess = (data) => ({
    type: FETCH_SHOPPING_LIST_ITEMS_SUCCESS,
    payload: { data }
  });

  export const fetchShoppingListItemsFailure = (error) => ({
    type: FETCH_SHOPPING_LIST_ITEMS_FAILURE,
    payload: { error }
  }); 