// sagas/dropDownCompaniesSaga.js
import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_DROP_DOWN_COMPANIES_REQUEST,
  fetchDropDownCompaniesSuccess,
  fetchDropDownCompaniesFailure,
} from "store/actions/drop-down-companies-action";
import { getRequest } from "api/apiCall";
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

function* fetchDropDownCompaniesSaga(action) {
  const queryParams = action.payload.split("?")[1];
  const page = queryParams.split("&")[0].split("=")[1];

  try {
    const resData = yield call(getRequest, action.payload);

    const pageAddedData = yield {
      ...resData,
      data: { ...resData.data, page: Number(page) },
    };

    yield put(fetchDropDownCompaniesSuccess(pageAddedData));
  } catch (error) {
    captureCustomSentryEvent("fetchDropDownCompanies", error);
    yield put(fetchDropDownCompaniesFailure(error.message));
  }
}

function* dropDownCompaniesSaga() {
  yield takeEvery(
    FETCH_DROP_DOWN_COMPANIES_REQUEST,
    fetchDropDownCompaniesSaga
  );
}

export default dropDownCompaniesSaga;
