import { SELECT_ACCOUNT_ACTION } from "store/actions/unifield-account-action";

// Get the initial state from localStorage if available
const storedAccount = localStorage.getItem('selectedAccount');
const initialState = {
  selectedAccount: storedAccount ? JSON.parse(storedAccount) : {},
};

export const unifiedAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_ACCOUNT_ACTION:
      return {
        ...state,
        selectedAccount: action.payload,
      };
    default:
      return state;
  }
};
