import {
  FETCH_SHOPPING_LIST_ITEMS_REQUEST,
  FETCH_SHOPPING_LIST_ITEMS_SUCCESS,
  FETCH_SHOPPING_LIST_ITEMS_FAILURE,
} from "../actions/fetchShoppingListItemsAction";

const initialState = {
  data: [],
  loading: false,
  error: null,
  shopping_list_id:"",
  total_page:0,
  total_records:0
};

const shoppingListItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHOPPING_LIST_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SHOPPING_LIST_ITEMS_SUCCESS:
      if(action.payload.data.page_number ===0){
        return {
          ...state,
          data: action.payload.data.data.shopping_list_items,
          total_page: Math.ceil(action.payload.data.total_records/20) -1,
          loading: false,
          shopping_list_id:action.payload.data.data.shopping_list_id,
          total_records:action.payload.data.total_records,
          error: null,
        };
        
      }


      return {
        ...state,
        data: [...state.data,...action.payload.data.data.shopping_list_items],
        loading: false,
        error: null,
      };
    case FETCH_SHOPPING_LIST_ITEMS_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default shoppingListItemsReducer;
