import salesrep from './salesrep';
import product from "./product";
import order from "./order";
import accountRecords from "./account-records";
import irDetails from './IR-details';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    product,
    salesrep,
    order.children[1],
    irDetails,
    accountRecords,
    order.children[0]
  ]
};

export default menuItems;
