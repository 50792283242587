// actions.js

// Action Types
export const FETCH_PRICE_LIST_REQUEST = 'FETCH_PRICE_LIST_REQUEST';
export const FETCH_PRICE_LIST_SUCCESS = 'FETCH_PRICE_LIST_SUCCESS';
export const FETCH_PRICE_LIST_FAILURE = 'FETCH_PRICE_LIST_FAILURE';

// Action Creators
export const fetchPriceListRequest = () => ({
  type: FETCH_PRICE_LIST_REQUEST,
});

export const fetchPriceListSuccess = (data) => ({
  type: FETCH_PRICE_LIST_SUCCESS,
  payload: {data},
});


export const fetchPriceListFailure = (error) => ({
  type: FETCH_PRICE_LIST_FAILURE,
  payload:{ error},
});
