import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import * as Sentry from "@sentry/react"
import { Provider } from 'react-redux';


import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import config from './config';
import { initAmplitude, setUserId } from './utils/amplitude';
import { initClarity, setClarityUserId } from './utils/clarity'

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const isProduction = process.env.REACT_APP_ENV === "production";
const sentryDns = process.env.REACT_APP_SENTRY_DNS

const history = createBrowserHistory();
if(isProduction){
  Sentry.init({
    dsn: sentryDns,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

initAmplitude();
setUserId();

initClarity();
setClarityUserId();

root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename} history={history}>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
