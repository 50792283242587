export const UPDATE_ANY_STATE = 'UPDATE_ANY_STATE';
export const FETCH_SALES_REP_RECORDS = 'FETCH_SALES_REP_RECORDS';
export const FETCH_SALES_REP_RECORDS_SUCCESS = 'FETCH_SALES_REP_RECORDS_SUCCESS';
export const FETCH_SALES_REP_RECORDS_FAILURE = 'FETCH_SALES_REP_RECORDS_FAILURE';
export const UPDATE_SALES_REP_OPTION = 'UPDATE_SALES_REP_OPTION';
export const UPDATE_CUSTOMER_OPTION = 'UPDATE_CUSTOMER_OPTION';
export const FETCH_DAILY_SALES_SUMMARY_SUCCESS = 'FETCH_DAILY_SALES_SUMMARY_SUCCESS';
export const FETCH_DAILY_SALES_SUMMARY_FAILURE = 'FETCH_DAILY_SALES_SUMMARY_FAILURE';
export const UPDATE_DAILY_DATE_OPTION = 'UPDATE_DAILY_DATE_OPTION';

export const FETCH_SALES_REP_CUSTOMERS_RECORDS = 'FETCH_SALES_REP_CUSTOMERS_RECORDS';
export const FETCH_SALES_REP_CUSTOMERS_RECORDS_SUCCESS = 'FETCH_SALES_REP_CUSTOMERS_RECORDS_SUCCESS';
export const FETCH_SALES_REP_CUSTOMERS_RECORDS_FAILURE = 'FETCH_SALES_REP_CUSTOMERS_RECORDS_FAILURE';

export const FETCH_DAILY_SALES_DETAILS_FAILURE = 'FETCH_DAILY_SALES_DETAILS_FAILURE';
export const FETCH_DAILY_SALES_DETAILS_SUCCESS = 'FETCH_DAILY_SALES_DETAILS_SUCCESS';
export const UPDATE_SUMMARY_VIEW = 'UPDATE_SUMMARY_VIEW';
export const RESET_SALESREP_LOADING = "RESET_SALESREP_LOADING";
export const updateAnyState = (data) => ({
    type: UPDATE_ANY_STATE,
    payload: data
});

export const fetchSalesRepsRecords = () => ({
    type: FETCH_SALES_REP_RECORDS
});


export const fetchSalesRepRecordsSuccess = (data) => ({
    type: FETCH_SALES_REP_RECORDS_SUCCESS,
    payload: { data }
});


export const fetchSalesRepRecordsFailure = (error) => ({
    type: FETCH_SALES_REP_RECORDS_FAILURE,
    payload: { error }
});


export const fetchSalesRepsCustomersRecords = () => ({
    type: FETCH_SALES_REP_CUSTOMERS_RECORDS
});


export const fetchSalesRepsCustomersRecordsSuccess = (data) => ({
    type: FETCH_SALES_REP_CUSTOMERS_RECORDS_SUCCESS,
    payload: { data }
});

export const fetchSalesRepsCustomersRecordsFailure = (error) => ({
    type: FETCH_SALES_REP_CUSTOMERS_RECORDS_FAILURE,
    payload: { error }
});

export const updatesalesRepOption = (data) => ({
    type: UPDATE_SALES_REP_OPTION,
    payload: { data }
});

export const fetchDailySalesSummarySuccess = (data) => ({
    type: FETCH_DAILY_SALES_SUMMARY_SUCCESS,
    payload: { data }
});


export const fetchDailySalesSummaryFailure = (error) => ({
    type: FETCH_DAILY_SALES_SUMMARY_FAILURE,
    payload: { error }
});

export const updateCustomerOption = (data) => ({
    type: UPDATE_CUSTOMER_OPTION,
    payload: { data }
});

export const fetchDailySalesDetailsSuccess = (data) => ({
    type: FETCH_DAILY_SALES_DETAILS_SUCCESS,
    payload: { data }
});

export const fetchDailySalesDetailsFailure = (error) => ({
    type: FETCH_DAILY_SALES_DETAILS_FAILURE,
    payload: { error }
});

export const updateDailyDateOption = (date) => ({
    type: UPDATE_DAILY_DATE_OPTION,
    payload: { date }
});


export const updateSummaryView = (view) => ({
    type: UPDATE_SUMMARY_VIEW,
    payload: { view }
});
