// actions/catalogPriceActions.js

export const FETCH_CATALOG_PRICE_REQUEST = 'FETCH_CATALOG_PRICE_REQUEST';
export const FETCH_CATALOG_PRICE_SUCCESS = 'FETCH_CATALOG_PRICE_SUCCESS';
export const FETCH_CATALOG_PRICE_FAILURE = 'FETCH_CATALOG_PRICE_FAILURE';

export const fetchCatalogPriceFilterRequest = (priceList,accountName) => ({
  type: FETCH_CATALOG_PRICE_REQUEST,
  payload:{priceList,accountName}
});

export const fetchCatalogPriceSuccess = (data) => ({
  type: FETCH_CATALOG_PRICE_SUCCESS,
  payload: {data},
});

export const fetchCatalogPriceFailure = (error) => ({
  type: FETCH_CATALOG_PRICE_FAILURE,
  payload: {error},
});
