import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_COMPANY_LIST_REQUEST, fetchCompanyListSuccess, fetchCompanyListFailure } from '../actions/fetchCompaniesAction';
import { getRequest } from 'api/apiCall';
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

// API URL
const apiUrl = '/shopping-lists/companies';

// API Request Function
function* fetchCompanyListAPI() {
  try {
    const response = yield call(getRequest, apiUrl);
    if (response) {
      return response;
    } else {
      throw new Error("Failed to fetch account list: " + response);
    }
  } catch (error) {
    captureCustomSentryEvent("fetchCompanyListAPI", error);
    throw error;
  }
}

// Worker Saga
function* fetchCompanyList() {
  try {
    const response = yield call(fetchCompanyListAPI);
    yield put(fetchCompanyListSuccess(response));
  } catch (error) {
    yield put(fetchCompanyListFailure(error));
  }
}

// Watcher Saga
function* watchFetchCompanyList() {
  yield takeLatest(FETCH_COMPANY_LIST_REQUEST, fetchCompanyList);
}

export default watchFetchCompanyList;