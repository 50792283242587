// actions.js

// Action Types
export const FETCH_COMPANY_LIST_REQUEST = 'FETCH_COMPANY_LIST_REQUEST';
export const FETCH_COMPANY_LIST_SUCCESS = 'FETCH_COMPANY_LIST_SUCCESS';
export const FETCH_COMPANY_LIST_FAILURE = 'FETCH_COMPANY_LIST_FAILURE';
export const FETCH_COMPANY_LIST_EMPTY = 'FETCH_COMPANY_LIST_EMPTY';

// Action Creators
export const fetchCompanyListRequest = () => ({
  type: FETCH_COMPANY_LIST_REQUEST,
});


export const fetchCompanyListEmpty = () => ({
  type: FETCH_COMPANY_LIST_EMPTY,
});

export const fetchCompanyListSuccess = (data) => ({
  type: FETCH_COMPANY_LIST_SUCCESS,
  payload: { data },
});

export const fetchCompanyListFailure = (error) => ({
  type: FETCH_COMPANY_LIST_FAILURE,
  payload: { error },
});