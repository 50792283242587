import {
  FETCH_ACTION_L1CATEGORY_REQUEST,
  FETCH_ACTION_L1CATEGORY_SUCCESS,
  FETCH_ACTION_L1CATEGORY_FAILURE,
} from "./../actions/fetchCategoryAction";

const initialState = {
  data: [],
  loading: false,
  error: null,
  next_keys: null,
  pages: 1,
  total_records: 0,
  hasNextPage: false,
};

const L1CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTION_L1CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ACTION_L1CATEGORY_SUCCESS:
      if (action.payload.data.page === 1) {
        return {
          ...state,
          data: [...action.payload.data.response.results],
          pages: Math.ceil(action.payload.data.response.count / 20),
          next_keys:action.payload.data.response.next_keys,
          loading: false,
          error: null,
        };
      }

      return {
        ...state,
        data: [...state.data, ...action.payload.data.response.results],
        next_keys:action.payload.data.response.next_keys,
        loading: false,
        error: null,
      };

    case FETCH_ACTION_L1CATEGORY_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default L1CategoryReducer;
