import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_SHOPPING_CART_ITEMS_REQUEST,
  fetchShoppingCartItemsSuccess,
  fetchShoppingCartItemsFailure
} from "../actions/fetchShoppingCartItemsAction";
import { getRequest } from "api/apiCall";
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

// Saga to fetch data from the API
function* fetchShoppingCartItemsFromApi(companyId, page, searchText) {
  try {
    let url = `/shopping-cart/shopping-cart-items?company_id=${companyId}&page=${page}`;
    if(searchText) {
      url = url + `&search=${searchText}`;
    }
    const response = yield call(getRequest, url);      
    if (response) {
    
      return response;
    } else {
      throw new Error("Failed to fetch shopping cart items: " + response.statusText);
    }
  } catch (error) {
    captureCustomSentryEvent("fetchShoppingCartItemsFromApi", error);
    throw error;
  }
}

function* fetchShoppingCartItemsSaga(action) {
  try {
    const { shoppingCartId, companyId, page, searchText } = action.payload;
    const data = yield call(fetchShoppingCartItemsFromApi, shoppingCartId, companyId, page, searchText);
    yield put(fetchShoppingCartItemsSuccess(data));
  } catch (error) {
    yield put(fetchShoppingCartItemsFailure(error.message));
  }
}

// Root saga for shopping cart related actions
function* shoppingCartItemsSaga() {
  yield takeEvery(FETCH_SHOPPING_CART_ITEMS_REQUEST, fetchShoppingCartItemsSaga);
}

export default shoppingCartItemsSaga;
