export const SET_FILES = 'SET_FILES';
export const SET_LOADING = 'SET_LOADING';
export const SET_UPLOAD_SUCCESS = 'SET_UPLOAD_SUCCESS';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SET_UPLOAD_STATUS = 'SET_UPLOAD_STATUS';
export const SET_ALERT_ERROR_MESSAGE = 'SET_ALERT_ERROR_MESSAGE';
export const SET_UPLOAD_POPUP_OPEN = 'SET_UPLOAD_POPUP_OPEN';
export const FILE_UPLOAD_REQUESTED = 'FILE_UPLOAD_REQUESTED';

export const setFiles = (files) => ({
  type: SET_FILES,
  payload: files,
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

export const setUploadSuccess = (success) => ({
  type: SET_UPLOAD_SUCCESS,
  payload: success,
});

export const setUploadProgress = (progress) => ({
  type: SET_UPLOAD_PROGRESS,
  payload: progress,
});

export const setUploadStatus = (status) => ({
  type: SET_UPLOAD_STATUS,
  payload: status,
});

export const setAlertErrorMessage = (message) => ({
  type: SET_ALERT_ERROR_MESSAGE,
  payload: message,
});

export const setUploadPopupOpen = (popupOpen) => ({
  type: SET_UPLOAD_POPUP_OPEN,
  payload: popupOpen,
});

export const fileUploadRequested = (files, selectedAccountID) => ({
  type: FILE_UPLOAD_REQUESTED,
  payload: { files, selectedAccountID },
});