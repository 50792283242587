import React, { useEffect } from "react";
import { Paper, TableContainer as MuiTableContainer } from "@mui/material";

const TableContainer = ({
  children,
  containerRef,
  handleScroll,
  currentPage,
  hasNext,
}) => {
  useEffect(() => {
    const handleScrollEvent = () => {
      const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
      if (
        hasNext &&
        scrollHeight - scrollTop - 1 <= clientHeight
      ) {
        handleScroll();
      }
    };

    const currentRef = containerRef.current; // Capture current value of containerRef

    currentRef.addEventListener("scroll", handleScrollEvent);

    return () => {
      currentRef.removeEventListener("scroll", handleScrollEvent);
    };
  }, [containerRef, handleScroll, currentPage, hasNext]);

  return (
    <MuiTableContainer
      sx={{ height: "calc(100vh - 225px)", minWidth: 650, overflow: "auto" }}
      component={Paper}
      ref={containerRef}
    >
      {children}
    </MuiTableContainer>
  );
};

export default TableContainer;
