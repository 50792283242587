import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_REQUEST_FAILURE,
  FETCH_LOGIN_REQUEST_SUCCESS,
  REFRESH_TOKEN_FAILURE, REFRESH_TOKEN_SUCCESS
} from "../actions/fetchAuthAction";
import { initAmplitude, setUserId } from '../../utils/amplitude';
import { setClarityUserId } from '../../utils/clarity';

const getRoles = (token) => {
  if (token) {
    try{
      const payload = token.split('.')[1];
      const decoded = atob(payload);
      const parsed = JSON.parse(decoded);
      return parsed.groups;
    }catch (e) {
      return [];
    }
  }else{
    return [];
  }
}


const initialState = {
  isAuthenticated: localStorage.getItem('accessToken') ? true : false,
  token: localStorage.getItem('accessToken'),
  roles: getRoles(localStorage.getItem('accessToken')) || [],
};



// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case FETCH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        token: null,
        refresh: null,
        error: undefined,
        isAuthenticated: false,
      };
    case FETCH_LOGIN_REQUEST_SUCCESS:
      id = action.id;
      if (action?.payload?.data?.access) {
        localStorage.setItem(`accessToken`, action.payload.data.access);
        localStorage.setItem(`refreshToken`, action.payload.data.refresh);
        setUserId();
        setClarityUserId();
        return {
          ...state,
          loading: false,
          token: action.payload.data.access,
          refresh: action.payload.data.refresh,
          error: undefined,
          isAuthenticated: true,
          roles: getRoles(action.payload.data.access),
        }
      };
    case FETCH_LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        token: null,
        isAuthenticated: false,
        error: action.payload.error,
      };
    case REFRESH_TOKEN_SUCCESS:
      localStorage.setItem(`accessToken`, action.payload.data.access);
      localStorage.setItem(`refreshToken`, action.payload.data.refresh);
      return {
        ...state,
        loading: false,
        token: action.payload.data.access,
        refresh: action.payload.data.refresh,
        isAuthenticated: true,
        error: null,
        roles: getRoles(action.payload.data.access),
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default authReducer;
