// assets
import { IconUser } from '@tabler/icons';

const irDetails = {
  id: 'ir-details',
  title: 'Users',
  type: 'group',
  children: [
    {
      id: 'irdetails',
      title: 'IR Management',
      type: 'item',
      url: '/user/ir-dashboard',
      icon: IconUser,
      breadcrumbs: false
    }
  ]
};

export default irDetails;
