// assets
import { IconPaperclip,IconDashboard, IconUsers} from '@tabler/icons';
const icons = { IconDashboard };
const accountRecords = {
    id: 'account-records',
    title: 'Companies',
    type: 'group',
    children: [
      {
        id: 'default',
        title: 'Accounts',
        type: 'item',
        url: '/company/companies',
        icon: IconUsers,
        breadcrumbs: false
      },
      {
        id: 'list',
        title: 'Account Records',
        type: 'item',
        url: '/account-records',
        icon: IconPaperclip,
        breadcrumbs:false
      }
    ]
  };

  export default accountRecords;
