import * as amplitude from "@amplitude/analytics-browser";
import { jwtDecode } from 'jwt-decode' // import dependency

const isProduction = process.env.REACT_APP_ENV === "production";

export const initAmplitude = () => {
    if (!isProduction) {
        console.log("Skipping Amplitude initialization in development environment");
        return;
    }
    amplitude.init(process.env.REACT_APP_AMPLITUDE, {
        defaultTracking: {
            attribution: true,
            pageViews: true,
            sessions: true,
            formInteractions: true,
            fileDownloads: true,
        },
    });

};

export const setUserId = () => {
    if (!isProduction) {
        console.log("Skipping Amplitude initialization in development environment");
        return;
    }
    const token = localStorage.getItem("accessToken");
    if (!token) {
        console.log("No access token found, skipping User ID initialization");
        return;
    }
    try {
        const user = jwtDecode(token);
        amplitude.setUserId(user.email);
    } catch (error) {
        console.error("Error decoding token or setting user ID:", error);
    }
}

export const clearAmpSession = () => {
    if (!isProduction) {
        console.log("Skipping Amplitude initialization in development environment");
        return;
    }
    amplitude.setUserId(null);
}