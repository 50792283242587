import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_SHOPPING_LIST_ITEMS_REQUEST,
  fetchShoppingListItemsSuccess,
  fetchShoppingListItemsFailure
} from "../actions/fetchShoppingListItemsAction";
import { getRequest } from "api/apiCall";
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

// Saga to fetch data from the API
function* fetchShoppingListItemsFromApi(shoppingListId, companyId,page, searchText) {
  try {
    let url = `/shopping-lists/shopping-list-items?shopping_list_id=${shoppingListId}&company_id=${companyId}&limit=20&page=${page}`;
    if(searchText) {
      url = url + `&search=${searchText}`;
    }
    const response = yield call(getRequest, url);
    if (response) {
      return response;
    } else {
      throw new Error("Failed to fetch shopping list items: " + response.statusText);
    }
  } catch (error) {
    captureCustomSentryEvent("fetchShoppingListItemsFromApi", error);
    throw error;
  }
}

function* fetchShoppingListItemsSaga(action) {
  try {
    const { shoppingListId, companyId ,page, searchText} = action.payload;
    const data = yield call(fetchShoppingListItemsFromApi,  companyId,shoppingListId,page, searchText);
    yield put(fetchShoppingListItemsSuccess(data));
  } catch (error) {
    yield put(fetchShoppingListItemsFailure(error.message));
  }
}


function* shoppingListItemsSaga() {
  yield takeEvery(FETCH_SHOPPING_LIST_ITEMS_REQUEST, fetchShoppingListItemsSaga);
}

export default shoppingListItemsSaga;