import * as Sentry from "@sentry/react";

/**
 * Captures a custom event with Sentry.
 *
 * @param {string} message - The message or description of the event.
 * @param {'info' | 'warning' | 'error' | 'log'} level - The severity level of the event.
 * @param {Record<string, any>} extraData - Additional data to be logged with the event.
 */
const captureCustomSentryEvent = (message, level, extraData = {}) => {
  Sentry.withScope(scope => {
    scope.setLevel(level); // "error", "warning", "info", etc.
    scope.setExtras(extraData); // Additional data to be sent with the event (must be serializable)

    Sentry.captureMessage(message);
  });
};

export default captureCustomSentryEvent;
