// reducers.js

import {
    FETCH_COMPANY_LIST_REQUEST,
    FETCH_COMPANY_LIST_SUCCESS,
    FETCH_COMPANY_LIST_FAILURE,
} from '../actions/fetchCompaniesAction';

const initialState = {
    data: [],
    loading: false,
    companyList: null,
    error: null,
};

const companyListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANY_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
                companyList: action.payload,
                error: null,
            };
        case FETCH_COMPANY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                companyList: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default companyListReducer;