import {
  FETCH_DOCUMENTS_REQUEST,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  GENERATE_VIEW_PRESIGNED_URL_REQUEST,
  GENERATE_VIEW_PRESIGNED_URL_SUCCESS,
  GENERATE_VIEW_PRESIGNED_URL_FAILURE,
  SET_HAS_MORE_PAGES,
  RESET_ACCOUNT_RECORDS_STATE,
} from "store/actions/accountRecordsAction";

const initialState = {
  accountRecords: [],
  loading: false,
  error: null,
  viewUrl: null,
  hasMorePages: true,
};

const accountRecordsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_REQUEST:
      return { ...state, loading: true };
    case FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        accountRecords: [...state.accountRecords, ...action.payload.items],
        loading: false,
      };
    case FETCH_DOCUMENTS_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case GENERATE_VIEW_PRESIGNED_URL_REQUEST:
      return { ...state, loading: true };
    case GENERATE_VIEW_PRESIGNED_URL_SUCCESS:
      return { ...state, viewUrl: action.payload, loading: false };
    case GENERATE_VIEW_PRESIGNED_URL_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case SET_HAS_MORE_PAGES:
      return { ...state, hasMorePages: action.payload };
    case RESET_ACCOUNT_RECORDS_STATE:
      return {...initialState, hasMorePages: true};
    default:
      return state;
  }
};

export default accountRecordsReducer;
