import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import salesrepReducer from "./salesrepReducer";
import salesDashboardReducer from "./salesDashboardReducer";
import catalogPriceReducer from "./catalogPriceReducer";
import priceListReducer from "./fetchPriceListReducer";
import catalogPriceFilterReducer from "./catalogPriceFIlterReducer";
import authReducer from "./authReducer";
import companyListReducer from "./fetchCompaniesListReducer";
import shoppingListReducer from "./shoppingListReducer";
import shoppingListItemsReducer from "./shoppingListItemsReducer";
import shoppingCartReducer from "./shoppingCartReducer";
import shoppingCartItemsReducer from "./shoppingCartItemsReducer";
import uploadRecordsReducer from "./UploadRecordsReducer";
import accountRecordsReducer from "./accountRecordsReducer";
import orderListReducer from "./orderListReducer";
import notificationReducer from "./fetchNotificationsReducer";
import orderDetailsReducer from "./fetchOrderDetailsReducer";
import IRDetailsReducer from "./IRDetailsReducer";
import companiesListReducer from "./companeisReducer";
import dropDownCompaniesReducer from "./drop-down-companies-reducer";
import { unifiedAccountReducer } from "./unifield-account-reducer";
import L1CategoryReducer from "./l1-category-reducer";
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  authentication: authReducer,
  customization: customizationReducer,
  salesrep: salesrepReducer,
  salesdashboard: salesDashboardReducer,
  catalograp: catalogPriceReducer,
  pricelistrap: priceListReducer,
  pricefilterrap: catalogPriceFilterReducer,
  companyListrap: companyListReducer,
  shoppingListFilterap: shoppingListReducer,
  pricefilterrap: catalogPriceFilterReducer,
  shoppingListItemrap: shoppingListItemsReducer,
  shoppingCartFilterap: shoppingCartReducer,
  irDetailsrap: IRDetailsReducer,
  shoppingCartItemrap: shoppingCartItemsReducer,
  orderList: orderListReducer,
  notificationData: notificationReducer,
  orderDetails: orderDetailsReducer,
  uploadRecords: uploadRecordsReducer,
  accountRecords: accountRecordsReducer,
  companyListRecords: companiesListReducer,
  dropDownCompanies: dropDownCompaniesReducer,
  account: unifiedAccountReducer,
  l1Category: L1CategoryReducer,
});

export default reducer;
