// Import any necessary dependencies
import { put, call, takeLatest, all, select } from "redux-saga/effects";
import fetchSalesRepRecordsWatcher from "./fetchSalesRepRecordsSaga";
import fetchSalesDashboardWatcher from "./fetchSalesDashboardSaga";
import updatesalesRepOptionWatcher from "./updatesalesRepOptionSaga";
import fetchSalesRepCustomersWatcher from "./fetchSalesRepCustomersSaga";
import updateCustomerOptionWatcher from "./updateCustomerOptionSaga";
import catalogSaga from "./fetchCatalogPriceSaga";
import watchFetchPriceList from "./fetchPriceListSaga";
import catalogPriceSaga from "./catalogPriceFilterSaga";
import watchFetchLogin from "./fetchAuthSaga";
import watchFetchCompanyList from "./fetchCompaniesSaga";
import shoppingListSaga from "./fetchShoppingListSaga";
import shoppingListItemsSaga from "./fetchShoppingListItemsSaga";
import shoppingCartItemsSaga from "./fetchShoppingCartItemsSaga";
import shoppingCartSaga from "./fetchShoppingCartSaga";
import uploadRecordsSaga from './uploadRecordsSaga';
import orderListSaga from './fetchOrderListSaga';
import notificationSaga from './fetchNotificationsSaga';
import fetchOrderDetailsSaga from './fetchOrderDetailsSaga';
import accountRecordsSaga from './fetchAccountRecordsSaga';
import irDetailsSaga from "./fetchIRDetailsSaga"; 
import companiesListSaga from "./companiesListSage";
import dropDownCompaniesSaga from "./drop-down-companies-sage";
import watchSelectAccount from "./unifield-account-saga";
import l1CategorySaga from "./l1-category-sage";


export default function* rootSaga() {
  yield all([
    fetchSalesDashboardWatcher(),
    fetchSalesRepCustomersWatcher(),
    updatesalesRepOptionWatcher(),
    updateCustomerOptionWatcher(),
    catalogSaga(),
    watchFetchPriceList(),
    watchFetchCompanyList(),
    catalogPriceSaga(),
    watchFetchLogin(),
    shoppingListSaga(),
    shoppingListItemsSaga(),
    shoppingCartItemsSaga(),
    shoppingCartSaga(),
    orderListSaga(),
    notificationSaga(),
    fetchOrderDetailsSaga(),
    uploadRecordsSaga(),
    accountRecordsSaga(),
    irDetailsSaga(), ,
    companiesListSaga(),
    dropDownCompaniesSaga(),
    watchSelectAccount(),
    l1CategorySaga()
  ]);
}
