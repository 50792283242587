export const FETCH_ACTION_L1CATEGORY_REQUEST =
  "FETCH_ACTION_L1CATEGORY_REQUEST";
export const FETCH_ACTION_L1CATEGORY_SUCCESS =
  "FETCH_ACTION_L1CATEGORY_SUCCESS";
export const FETCH_ACTION_L1CATEGORY_FAILURE =
  "FETCH_ACTION_L1CATEGORY_FAILURE";

export const fetchL1category = (params) => {
  return {
    type: FETCH_ACTION_L1CATEGORY_REQUEST,
    payload: { params },
  };
};

export const fetchL1categorySuccess = (data) => {
  return {
    type: FETCH_ACTION_L1CATEGORY_SUCCESS,
    payload: { data },
  };
};

export const fetchL1categoryFailure = (data) => {
  return {
    type: FETCH_ACTION_L1CATEGORY_SUCCESS,
    payload: { data },
  };
};
