import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ReusableCircularBar from "ui-component/proccess-bars";
import { debounce } from 'lodash';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  backgroundColor: "#f8fafc",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  border: "1px solid  #00000040",
  borderRadius: "4px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  color: "#00000040",
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: "15px 14px",
    width: "100%",
    paddingLeft: "50px",
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  "& .MuiInputBase-root": {
    width: "100%",
  },
}));

function ModelSearchBar({ setSearchText, loading, setPage }) {
  const debouncedSetSearchText = React.useMemo(() => debounce((value) => {
    setSearchText(value);
    setPage(()=>1);
  }, 500), [setSearchText, setPage]);

  const handleSearchInput = (e) => {
    debouncedSetSearchText(e.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        gap: "15px",
        height: 36,
      }}
    >
      <Search
        style={{
          width: "100%",
        }}
      >
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={`Search for an Account`}
          inputProps={{ "aria-label": "Search for an Account" }}
          sx={{ color: "white", display: "flex" }}
          onChange={handleSearchInput}
        />
        {loading &&   <ReusableCircularBar
          size={17}
          color="#0a2972"
          thickness={4}
          boxSx={{
            justifyContent: "center",
            alignItems: "center",
            marginRight: "10px  ",
          }}
        />}
      
      </Search>
    </div>
  );
}

export default ModelSearchBar;
