import React, { useEffect, useState, useRef } from "react";
import MainCard from "ui-component/cards/MainCard";
import { Button} from "@mui/material";
import { connect, useSelector } from "react-redux";
import IRForm from "./IRForm";
import IRTable from "./IRTable";
import { fetchIRDetailsRequest } from "store/actions/IRDetailsActions";
import { fetchCompanyListRequest } from "store/actions/fetchCompaniesAction";
import CustomizedProgressBars from "ui-component/CirculerProcessBar";
import "./IRDashboard.css";

const IRDashboard = (props) => {
  const { fetchIRDetailsRequest, fetchCompanyListRequest } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const containerRef = useRef();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchIRDetailsRequest(currentPage);
    fetchCompanyListRequest();
  }, [fetchIRDetailsRequest, fetchCompanyListRequest, currentPage, open]);

  const { data: IRData, hasNextPage } = useSelector((state) => state.irDetailsrap);
  const { data: companyListData } = useSelector((state) => state.companyListrap);

  const defaultCompanyId = companyListData?.find(company => company.company_name === "CoolKurt")?.company_id;



  return (
    <div className="dashboard-container">
      <MainCard className="main-card">
        <IRForm open={open} setOpen={setOpen} isUpdate={false} companyListData={companyListData} defaultCompanyId={defaultCompanyId}/>
        <Header setOpen={setOpen} />
        <IRTable data={IRData} currentPage={currentPage} setCurrentPage={setCurrentPage} loading={props.IRListLoading} containerRef={containerRef} hasNext={hasNextPage} companyListData={companyListData}/>
        {props.IRListLoading && <CustomizedProgressBars />}
        </MainCard>
    </div>
  );
};

const Header = ({ setOpen }) => (
  <div className="header-container">
    <div className="header-title">
      <h3>IR Dashboard</h3>
    </div>
    <Button onClick={() => setOpen(true)} className="btn smallButton" variant="contained">
      Add New
    </Button>
  </div>
);

const mapStateToProps = (state) => ({
  IRData: state.irDetailsrap.data,
  IRListLoading: state.irDetailsrap.loading,
  hasNextPage: state.irDetailsrap.hasNextPage,
  companyListData: state.companyListrap.data,
});

const mapDispatchToProps = {
  fetchIRDetailsRequest,
  fetchCompanyListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(IRDashboard);
