import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

// Inspired by the former Facebook spinners.
export function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        // disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#0056b3" : "#0056b3",
          // animationDuration: "990ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default function CustomizedProgressBars({color}) {
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {" "}
      <Box sx={{ width: 40 }}>
        <FacebookCircularProgress />
      </Box>
    </div>
  );
}

export function CircularIndeterminate({ color, size , width="14px"}) {
  return (
    <Box sx={{ display: "flex", marginLeft: "10px", width:width }}>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: color || "#0056b3",
          animationDuration: "550ms",

          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={size||13}
        thickness={4}
      />
    </Box>
  );
}
