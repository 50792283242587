import { useRoutes } from 'react-router-dom';

import {useSelector} from "react-redux";
import MainRoutes from "./MainRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { isAuthenticated,roles } = useSelector((state) => state.authentication);

  return useRoutes([MainRoutes(isAuthenticated,roles)]);
}
