// sagas/orderListSaga.js
import { call, put, takeEvery } from 'redux-saga/effects';

import { FETCH_COMPANIES_REQUEST, fetchCompaniesSuccess,fetchCompaniesFailure}  from 'store/actions/fetchCompanysListActions';
import { getRequest } from 'api/apiCall';
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

function* fetchCompaniesListSaga(action) {
  try {
    const data = yield call(getRequest, action.payload.url);
    yield put(fetchCompaniesSuccess(data));
  } catch (error) {
    captureCustomSentryEvent("fetchOrderList", error);
    yield put(fetchCompaniesFailure(error.message));
  }
}

function* companiesListSaga() {
  yield takeEvery(FETCH_COMPANIES_REQUEST, fetchCompaniesListSaga);
}

export default companiesListSaga;
