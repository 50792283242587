// Import any necessary dependencies
import { put, call, takeLatest, all, select } from "redux-saga/effects";
import {
  FETCH_SALES_DASHBOARD,
  fetchSalesDashboardSuccess,
  fetchSalesDashboardFailure,
  updateAnyState,
} from "../actions/fetchSalesDashboardActions";
import { BASE_BACKEND_URL } from "../constant";
import { toast } from "react-toastify";
import { getUserEmailFromAccessToken } from "utils/clarity";

const fetchSalesDashboardAPI = async (resourceId, params) => {
  let url = `${BASE_BACKEND_URL}/data_syncs/reports?resource_id=${resourceId}`;
  if (params) {
    url += `&params={"select_sales_rep": ${encodeURIComponent(JSON.stringify(params))}}`;
  }
  const response = await fetch(url);
  if (!response.ok) {
    toast.error("Error fetching file details", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    throw new Error("Error fetching file details");
  }
  const data = await response.json();
  return data;
};

function* fetchSalesDashboardWorker(action) {
  try {
      yield put(updateAnyState({ loading: true }));

      let params = null;
      if (action.payload.isIRDashboard) {
        const user = getUserEmailFromAccessToken();
        if (user && user.sales_rep_details && user.sales_rep_details.name) {
          params = user.sales_rep_details.name;
        }
      }
      const data = yield call(fetchSalesDashboardAPI,action.payload.resourceId, params);
      yield put(fetchSalesDashboardSuccess(data));
  } catch (error) {
    yield put(fetchSalesDashboardFailure(error.message));
  }
}

function* fetchSalesDashboardWatcher() {
  yield takeLatest(FETCH_SALES_DASHBOARD, fetchSalesDashboardWorker);
}
export default fetchSalesDashboardWatcher;
