// Action types for fetch notification
export const FETCH_NOTIFICATION_REQUEST = 'FETCH_NOTIFICATION_REQUEST';
export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_FAILURE = 'FETCH_NOTIFICATION_FAILURE';

// Action creators for fetch notification
export const fetchNotificationRequest = (params) => ({
    type: FETCH_NOTIFICATION_REQUEST,
    payload:{params}
});

export const fetchNotificationSuccess = (data) => ({
    type: FETCH_NOTIFICATION_SUCCESS,
    payload: { data },
    

});

export const fetchNotificationFailure = (error) => ({
    type: FETCH_NOTIFICATION_FAILURE,
    payload: { error },
});
