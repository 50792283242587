import React from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';

function UserUpdateStatus({ response, handleClose, setSaving, name ,updateIR }) {
  return (
    <div style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {response.response ? (
          <li style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            { <p><span>Account details Successfully updated for  </span>{' '}<strong>{name}.</strong></p> /*You can copy the credentials given below to send them to {email}</p> */}
            
          </li>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <p><span>Please confirm if you want to update details for </span> {' '}<strong>{name}.</strong></p>
          </div>
        )}
      </ul>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: "20px" }}>
      {response.response ?<>
        <Button variant="contained" style={{ backgroundColor: "#0a2972", color: 'white' }} onClick={handleClose}>
         Close
        </Button></>:
      <Button variant="outlined" style={{  marginRight: 10, borderColor: 'blue', color: 'blue' }} onClick={()=>setSaving(false)}>
         Cancel
        </Button>}
        {response.response ?<></>:
        <Button variant="contained" style={{ backgroundColor: "#0a2972", color: 'white' }} onClick={updateIR}>
          Confirm
        </Button>}
      </div>
    </div>
  );
}

export default UserUpdateStatus;
