import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { BASE_DATA_COTTAGE_BACKEND_URL } from "store/constant";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { red } from "@mui/material/colors";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import { DeleteForeverOutlined } from "@mui/icons-material";
import Select from "react-select";
import UserCreationStatus from "./UserCreationStatus";
import UserUpdateStatus from "./UserUpdateStatus";
import { IRROLE } from "./IRConstants";
import { customStyles, handleFormChange, handleEditIrForm, handleSubmitForm, validatePhoneNumber, isFormDataEmpty, formatPhoneNumber } from "./IRFormUtils";
import AccountAssignedPopup from "./AccountAssignedPopup";
import "./IRForm.css";

const IRForm = ({ open, setOpen, companyListData, defaultCompanyId, userDetails,onFormSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [apiEmailError, setApiEmailError] = useState(false);
  const [apiUsernameError, setApiUsernameError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isUpdateSuccess, setUpdateSuccess] = useState(false);
  const [response, setResponse] = useState({});
  const [popupOpen, setPopupOpen] = useState(false)
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    ir_username: "",
    phone_number: "",
    email: "",
    ir_password: "",
    confirm_password: "",
    role: IRROLE, // default role
    companies: defaultCompanyId ? [defaultCompanyId] : [], // array to store selected company IDs
  });

  const [assignedCompanies, setAssignedCompanies] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({
    email: false,
    ir_password: false,
    confirm_password: false,
    ir_username: false,
    first_name: false,
    last_name: false,
    phone_number: false,
  });

  const handleInputChange = (newValue) => {
    const selectedCompanyIds = newValue.map((option) => option.value);
    setFormData({
      ...formData,
      companies: selectedCompanyIds,
    });
  };

  const handleSubmit = (event) => {
    handleSubmitForm(event, setLoading, formData, setFormErrors, setApiEmailError, setApiUsernameError, setResponse, setSuccess);
  };

  const handleClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  useEffect(() => {
    if (!userDetails) return
    // Reset form state when dialog closes
    setFormData({
      ...formData,
      first_name: userDetails.name.split(' ')[0],
      last_name: userDetails.name.split(' ')[1],
      phone_number: userDetails.phone_number?.startsWith("+1-") ? userDetails.phone_number.substring(3) : userDetails.phone_number?.startsWith("+1") ? userDetails.phone_number.substring(2) : userDetails.phone_number,
      email: userDetails.email,
      ir_username: userDetails.username? userDetails.username :"<User Name not found>",
      companies: [
        ...userDetails.companies.map(company => company.id),
        ...(userDetails.companies.some(company => company.id === defaultCompanyId) ? [] : [defaultCompanyId])
      ]
    });
    setAssignedCompanies(userDetails?.companies?.map(company => company.id) || [])
  }, [userDetails]);

  useEffect(() => {
    if (!open) {
      // Reset form state when dialog closes
      setFormData({
        first_name: "",
        last_name: "",
        ir_username: "",
        phone_number: "",
        email: "",
        ir_password: "",
        confirm_password: "",
        role: IRROLE,
        companies: defaultCompanyId ? [defaultCompanyId] : [],
        defaultCompanyId: defaultCompanyId,
      });
      setFormErrors({
        email: false,
        ir_password: false,
        confirm_password: false,
        ir_username: false,
        first_name: false,
        last_name: false,
        phone_number: false,
      });
    }
  }, [open, defaultCompanyId]);

  const [formEnabled, setFormEnabled] = useState(true); // Initialize form as enabled

  // Effect to update formEnabled state based on formData changes
  useEffect(() => {
    setFormEnabled(isFormDataEmpty(formData));
  }, [formData]);


  // Function to fetch company info based on selected IDs
  async function fetchCompanyInfo(companyIds) {
    const url = `${BASE_DATA_COTTAGE_BACKEND_URL}/customers/get-company-ir?company_ids=${companyIds.join(',')}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      return null;
    }
  }

  const handleSave = async () => {
    const userCompanyIds = userDetails.companies.map(({ id }) => id)
    const currentSelectedCompaniesIds = formData.companies;
    const newlyAddedCompanyIds = currentSelectedCompaniesIds
      .filter((label) => !userCompanyIds.includes(label))

    if (newlyAddedCompanyIds.length > 0) {
      const response = await fetchCompanyInfo(newlyAddedCompanyIds);
      if (response && response?.count_with_sales_rep > 1) {
        setAssignedCompanies(response?.companies);
        setPopupOpen(true);
        return;
      }
      else {
        setSaving(true)
      }
    }
    else {
      setSaving(true)
    }
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };


  const handleChange = (event) => {
    handleFormChange(event, formData, setFormData, formErrors, setFormErrors, setApiUsernameError, setApiEmailError);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleRemoveCompany = (companyId) => {
    const updatedCompanies = formData.companies.filter((id) => id !== companyId);
    setFormData({
      ...formData,
      companies: updatedCompanies,
    });
  };

  const getFormHeading = () => {
    if (response.response) return "Account Details Update"
    if (saving) return "Are you sure?"
    if (userDetails) return "Edit Independent Representative"
    if (!success) return "Add New Independent Representative"
    return response.bttn_ui && response.bigcommerce_flag
      ? "New IR Successfully Created"
      : "New IR Account Not Created"
  }

  const newlyAddedCompanyIds = () => {
    const userCompanyIds = assignedCompanies.map((company_id, index) => company_id)
    const currentSelectedCompaniesIds = formData.companies;
    return currentSelectedCompaniesIds
      .filter((label) => !userCompanyIds.includes(label))
  }

  const deletedCompanies = () => {
    const userCompanyIdsForDelete = userDetails.companies.map(({ id }) => id)
    const deletedCompanies = userCompanyIdsForDelete
      .filter((id) => !formData?.companies?.includes(id))
    return deletedCompanies
  }

  const hasAnyFieldUpdated = (newlyAddedCompanyIds, deletedCompanies, phone_number) => {
    if (newlyAddedCompanyIds.length-1 || deletedCompanies.length) {
      return true;
    }
    const user_mob_unchanged = userDetails.phone_number?.startsWith("+1-") ? userDetails.phone_number.substring(3) : userDetails.phone_number?.startsWith("+1") ? userDetails.phone_number.substring(2) : userDetails.phone_number;
    if (phone_number !== formatPhoneNumber(user_mob_unchanged)) {
      return true;
    }
    return false;
  }

  const updateIR = () => {
    const additionalData = {
      email: formData.email,
      phone_number: formData.phone_number,
      defaultCompanyId: defaultCompanyId
    }
    try {
      handleEditIrForm(setLoading, additionalData, formData.companies, deletedCompanies(), setResponse, setUpdateSuccess,onFormSuccess,companyListData
          .map((company) => ({
            id: company.company_id,
            name: company.company_name,
          })) || []);
    } catch {
    }
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title" className="dialog-title">
          <h2>{getFormHeading()}</h2>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {success === false ?
          saving ?
            <UserUpdateStatus response={response} handleClose={handleClose} setSaving={setSaving} name={formData.first_name + " " + formData.last_name} updateIR={updateIR} /> :
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="form-content">
                <TextField
                  fullWidth
                  margin="normal"
                  label="Enter First Name"
                  name="first_name"
                  value={formData?.first_name}
                  onChange={handleChange}
                  error={formData?.first_name?.length === 0 || (formData?.first_name?.trim() === '') ? formErrors?.first_name : null}
                  helperText={formErrors?.first_name && (formData?.first_name?.length === 0 || (formData?.first_name.trim() === '')) ? "First Name Required" : ""}
                  className="form-field"
                  disabled={userDetails ? true : false}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Enter Last Name"
                  name="last_name"
                  value={formData?.last_name}
                  onChange={handleChange}
                  error={formData?.last_name?.length === 0 || (formData?.last_name?.trim() === '') ? formErrors?.last_name : null}
                  helperText={formErrors?.last_name && (formData?.last_name?.length === 0 || (formData?.last_name.trim() === '')) ? "Last Name Required" : ""}
                  className="form-field"
                  disabled={userDetails ? true : false}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Enter User Name"
                  name="ir_username"
                  type="text"
                  value={formData?.ir_username}
                  onChange={handleChange}
                  error={formData?.ir_username?.length === 0 || (formData?.ir_username?.includes(' ')) ? formErrors?.ir_username : apiUsernameError}
                  helperText={formErrors?.ir_username && formData?.ir_username?.length === 0 || (formData?.ir_username.includes(' ')) ? "Username Required" : apiUsernameError ? "Username Already Exist" : ""}
                  className="form-field"
                  autoComplete="off"
                  disabled={userDetails ? true : false}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Enter Phone Number"
                  name="phone_number"
                  placeholder="xxx-xxx-xxxx"
                  value={formData?.phone_number}
                  onChange={handleChange}
                  error={!validatePhoneNumber(formData?.phone_number) ? formErrors?.phone_number : null}
                  helperText={
                    formErrors?.phone_number && !validatePhoneNumber(formData?.phone_number)
                      ? formData?.phone_number?.length === 0
                        ? "Phone Number Required"
                        : "Phone Number is Invalid"
                      : ""
                  }
                  className="form-field"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <b style={{ userSelect: 'none', pointerEvents: 'none' }}>+1- </b>
                      </InputAdornment>
                    ),
                    inputMode: "numeric", // Ensures numeric keyboard on mobile devices
                    pattern: "[0-9]*" // Allows only numeric input
                  }}
                  type="tel" // Sets the input type to tel (for phone numbers)
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Removes non-numeric characters
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10); // Limits to 10 digits
                    }}}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Enter bttnusa.com email address"
                  name="email"
                  value={formData?.email}
                  onChange={handleChange}
                  error={
                    !formData?.email?.endsWith("@bttnusa.com") || formData?.email?.includes(' ')
                      ? formErrors?.email || formErrors?.invalid_email
                      : apiEmailError
                  }
                  helperText={
                    !formData?.email.endsWith("@bttnusa.com") || formData?.email?.includes(' ')
                      ? formErrors?.email
                        ? "Please provide a valid email address"
                        : formErrors?.invalid_email
                          ? "Please provide a valid email address"
                          : ""
                      : apiEmailError ? "Email Already Exist" : ""
                  }
                  className="form-field"
                  disabled={userDetails ? true : false}
                />
                {
                  userDetails ? <></> :
                    <>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Enter Password"
                        name={"ir_password"}
                        type={showPassword ? "text" : "password"}
                        value={formData.ir_password}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={togglePasswordVisibility} edge="end">
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={
                          formData.ir_password.length < 6
                            ? formErrors.ir_password || formErrors.password_length
                            : null
                        }
                        helperText={
                          formData.ir_password.length < 6
                            ? formErrors.ir_password
                              ? "Password Required"
                              : formErrors.password_length
                                ? "Min length should be more than 6 letters"
                                : ""
                            : ""
                        }
                        className="form-field"
                        autoComplete="new-password"
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Confirm Password"
                        name="confirm_password"
                        type={showConfirmPassword ? "text" : "password"}
                        value={formData.confirm_password}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                                {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={formErrors.confirm_password}
                        helperText={formErrors.confirm_password ? "Passwords do not match" : ""}
                        className="form-field"
                      />
                    </>
                }
                {formData.role === IRROLE && (
                  <>
                    {<h4 className="form-subtitle">Assign Accounts to IR:</h4>}
                    <label htmlFor="company-select">Search for accounts</label>
                    <Select
                      options={companyListData
                        .map((company) => ({
                          value: company.company_id,
                          label: company.company_name,
                        }))
                        .filter(({ value }) => value !== defaultCompanyId)}
                      isMulti
                      onChange={handleInputChange}
                      value={
                        formData.companies
                          .filter((companyId) =>
                            companyListData.some((company) => company.company_id === companyId)
                          )
                          .map((companyId) => ({
                            value: companyId,
                            label: companyListData.find((company) => company.company_id === companyId)?.company_name,
                          }))
                          .concat(
                            !formData.companies.length
                              ? [{ value: defaultCompanyId, label: 'CoolKurt' }]
                              : []
                          )
                      }
                      styles={customStyles} // Apply custom styles here
                      className="form-select"
                      id="company-select"
                    />

                    {/* Selected companies display */}
                    {formData.companies.length > 0 && (
                      <div className="selected-companies">
                        <h4>Associated Accounts:</h4>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                          {/* Check if CoolKurt is already in formData.companies */}
                          {(
                            <li key="defaultCompanyId" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox
                                  checked={true}
                                  disableRipple
                                  inputProps={{ "aria-label": "controlled" }}
                                  sx={{
                                    color: "#2196F3",
                                    "&.Mui-checked": {
                                      color: "#2196F3",
                                    },
                                    pointerEvents: "none",
                                  }}
                                />
                                <span className="company-name">CoolKurt</span>
                              </div>
                            </li>
                          )}

                          {/* Render existing companies */}
                          {formData.companies.map((companyId) => {
                            const company = companyListData.find((company) => company.company_id === companyId && company.company_id !== defaultCompanyId);
                            return (
                              (company &&
                                (<li key={companyId} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox
                                      checked={true}
                                      disableRipple
                                      inputProps={{ "aria-label": "controlled" }}
                                      sx={{
                                        color: "#2196F3",
                                        "&.Mui-checked": {
                                          color: "#2196F3",
                                        },
                                        pointerEvents: "none",
                                      }}
                                    />
                                    <span className="company-name">{company.company_name}</span>
                                  </div>
                                  <IconButton
                                    size="small"
                                    onClick={() => handleRemoveCompany(companyId)}
                                    className="remove-company-button"
                                  >
                                    <DeleteForeverOutlined fontSize="small" style={{ color: red[300] }} />
                                  </IconButton>
                                </li>)
                              )
                            );
                          })}
                        </ul>
                      </div>
                    )}

                    {/* If no companies exist in formData.companies, show CoolKurt as default */}
                    {formData.companies.length === 0 && (
                      <div className="selected-companies">
                        <h4>Associated Accounts:</h4>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                          <li key="defaultCompanyId" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Checkbox
                                checked={true}
                                disableRipple
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{
                                  color: "#2196F3",
                                  "&.Mui-checked": {
                                    color: "#2196F3",
                                  },
                                  pointerEvents: "none",
                                }}
                              />
                              <span className="company-name">CoolKurt</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}

                  </>
                )}

                <div className="form-actions">
                  {userDetails ?
                    <></> :
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      className="form-btn"
                      style={{
                        marginRight: 10,
                        borderColor: 'red',
                        color: 'red'
                      }}
                    >
                      Cancel
                    </Button>}
                  {
                    userDetails ?
                      <Button onClick={() => { handleSave() }} color="primary" variant="contained" className="form-btn"
                        disabled={formEnabled || loading || formErrors.phone_number || !hasAnyFieldUpdated(newlyAddedCompanyIds(), deletedCompanies(), formData.phone_number)}
                      >
                        {saving ? "Saving..." : "Save"}
                      </Button>
                      :
                      <Button type="submit" color="primary" variant="contained" className="form-btn" disabled={formEnabled || loading}>
                        {loading ? "Adding..." : "Add User"}
                      </Button>

                  }
                </div>

              </div>
            </form> :
          (<UserCreationStatus response={response} handleClose={handleClose} name={formData.first_name + " " + formData.last_name} username={formData.ir_username} password={formData.ir_password} email={formData.email} />)}
        <AccountAssignedPopup
          open={popupOpen}
          handleClose={handleClosePopup}
          companies={assignedCompanies?.filter(assignedCompanies => assignedCompanies?.id !== defaultCompanyId) || []}
        />
      </Dialog>
    </>
  );
};

export default IRForm;
