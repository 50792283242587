import { clarity } from 'react-microsoft-clarity';
import { jwtDecode } from 'jwt-decode' // import dependency

const isProduction = process.env.REACT_APP_ENV === "production";

export const initClarity = () => {
    if (!isProduction) {
        console.log("Skipping Clarity initialization in development environment");
        return;
    }
    clarity.init(process.env.REACT_APP_CLARITY_CODE); // Initialization clarity with code
};

export function getUserEmailFromAccessToken() {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        try {
            const user = jwtDecode(accessToken);
            return user;
        } catch (error) {
            return null;
        }
    }
    return null;
  }

export const setClarityUserId = () => {
    if (!isProduction) {
        console.log("Skipping Clarity's User initialization in development environment");
        return;
    }
    const user = getUserEmailFromAccessToken();

    const identifyUser = (user) => {
        if (clarity.hasStarted()) {
            clarity.identify(`${user.user_id}`, {bttnUserId: user.user_id, email: user.email });
        } else {
            setTimeout(() => identifyUser(user), 1000); // Retry a short delay
        }
    };

    if (user) {
        identifyUser(user);
    }
}