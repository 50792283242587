// assets
import {IconDashboard, IconGardenCart, IconInbox} from '@tabler/icons';

// constant

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const order = {
  id: 'order',
  title: 'order',
  type: 'group',
  children: [
    {
      id: 'cart',
      title: 'Cart',
      type: 'item',
      url: '/order/cart',
      icon: IconGardenCart,
      breadcrumbs:false
    }
    ,{
      id: 'Order',
      title: 'Orders',
      type: 'item',
      url: '/order/orders',
      icon: IconInbox,
      breadcrumbs:false
    }
  ]
};

export default order;
