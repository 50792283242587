import {
    FETCH_CATALOG_REQUEST,
    FETCH_CATALOG_PRICE_SUCCESS,
 

    FETCH_CATALOG_PRICE_FAILURE,

  } from '../actions/fetchCatalogPriceAction';

  const initialState = {
    data: [],
    loading: false,
    hasNextPage:false,
    error: null,
  };

  const catalogPriceReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CATALOG_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_CATALOG_PRICE_SUCCESS:
    

        if(action.payload.data["previous"] == null){
          return { ...state, loading: false, error: null,data:action.payload.data.results ,hasNextPage: action.payload.data.next};
        }
      return { ...state, loading: false, error: null,data:[...state.data,...action.payload.data.results],hasNextPage: action.payload.data.next !== null};

      case FETCH_CATALOG_PRICE_FAILURE:

        return { ...state, loading: false, error: action.payload.error };
      default:
        return state;
    }
  };

  export default catalogPriceReducer;



