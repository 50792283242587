import {
    SET_FILES,
    SET_LOADING,
    SET_UPLOAD_SUCCESS,
    SET_UPLOAD_PROGRESS,
    SET_UPLOAD_STATUS,
    SET_ALERT_ERROR_MESSAGE,
    SET_UPLOAD_POPUP_OPEN,
  } from 'store/actions/UploadRecordsAction';
  
  const initialState = {
    files: [],
    loading: false,
    uploadSuccess: false,
    uploadProgress: {},
    uploadStatus: {},
    alertErrorMessage: null,
    popupOpen: false,
  };
  
  const uploadRecordsReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_FILES:
        return { ...state, files: action.payload };
      case SET_LOADING:
        return { ...state, loading: action.payload };
      case SET_UPLOAD_SUCCESS:
        return { ...state, uploadSuccess: action.payload };
      case SET_UPLOAD_PROGRESS:
        return { ...state, uploadProgress: { ...state.uploadProgress, ...action.payload } };
      case SET_UPLOAD_STATUS:
        return { ...state, uploadStatus: { ...state.uploadStatus, ...action.payload } };
      case SET_ALERT_ERROR_MESSAGE:
        return { ...state, alertErrorMessage: action.payload };
      case SET_UPLOAD_POPUP_OPEN:
        return { ...state, popupOpen: action.payload };
      default:
        return state;
    }
  };
  
  export default uploadRecordsReducer;