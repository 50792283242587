import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function ReusableCircularBar({
  size = 40,
  color = 'primary',
  thickness = 3.6,
  boxSx = {},
  ...props
}) {
  return (
    <Box sx={{ display: 'flex', ...boxSx }}>
      <CircularProgress size={size} sx={{color:color}}  thickness={thickness} {...props} />
    </Box>
  );
}
