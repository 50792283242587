export const FETCH_SHOPPING_LIST_REQUEST = 'FETCH_SHOPPING_LIST_REQUEST';
export const FETCH_SHOPPING_LIST_SUCCESS = 'FETCH_SHOPPING_LIST_SUCCESS';
export const FETCH_SHOPPING_LIST_FAILURE = 'FETCH_SHOPPING_LIST_FAILURE';

export const fetchShoppingListRequest = (companyId) => ({
  type: FETCH_SHOPPING_LIST_REQUEST,
  payload: { companyId },
});

export const fetchShoppingListSuccess = (data) => ({
  type: FETCH_SHOPPING_LIST_SUCCESS,
  payload: { data },
});

export const fetchShoppingListFailure = (error) => ({
  type: FETCH_SHOPPING_LIST_FAILURE,
  payload: { error },
});