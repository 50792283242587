
import {
    FETCH_SHOPPING_LIST_REQUEST,
    FETCH_SHOPPING_LIST_SUCCESS,
    FETCH_SHOPPING_LIST_FAILURE,
  } from '../actions/shoppingListActions';

  const initialState = {
    data: [],
    loading: false,
    error: null,
  };

  const shoppingListReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SHOPPING_LIST_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_SHOPPING_LIST_SUCCESS:
        return {
          ...state,
          data: action.payload.data,
          loading: false,
          error: null,
        };
      case FETCH_SHOPPING_LIST_FAILURE:
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload.error,
        };
      default:
        return state;
    }
  };

  export default shoppingListReducer;