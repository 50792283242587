export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';
export const GENERATE_VIEW_PRESIGNED_URL_REQUEST = 'GENERATE_VIEW_PRESIGNED_URL_REQUEST';
export const GENERATE_VIEW_PRESIGNED_URL_SUCCESS = 'GENERATE_VIEW_PRESIGNED_URL_SUCCESS';
export const GENERATE_VIEW_PRESIGNED_URL_FAILURE = 'GENERATE_VIEW_PRESIGNED_URL_FAILURE';
export const SET_HAS_MORE_PAGES = 'SET_HAS_MORE_PAGES';
export const RESET_ACCOUNT_RECORDS_STATE = 'RESET_ACCOUNT_RECORDS_STATE';
  
  export const fetchDocumentsRequest = (selectedCompanyID, page) => ({
    type: FETCH_DOCUMENTS_REQUEST,
    payload: { selectedCompanyID, page },
  });
  
  export const fetchDocumentsSuccess = (data) => ({
    type: FETCH_DOCUMENTS_SUCCESS,
    payload: data,
  });
  
  export const fetchDocumentsFailure = (error) => ({
    type: FETCH_DOCUMENTS_FAILURE,
    payload: error,
  });
  
  export const generateViewPresignedUrlRequest = (payload) => ({
    type: GENERATE_VIEW_PRESIGNED_URL_REQUEST,
    payload,
  });
  
  export const generateViewPresignedUrlSuccess = (url) => ({
    type: GENERATE_VIEW_PRESIGNED_URL_SUCCESS,
    payload: url,
  });
  
  export const generateViewPresignedUrlFailure = (error) => ({
    type: GENERATE_VIEW_PRESIGNED_URL_FAILURE,
    payload: error,
  });

  export const resetAccountRecordsState = () => ({
    type: RESET_ACCOUNT_RECORDS_STATE,
  });