import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router";
import { Avatar, Box, ButtonBase } from "@mui/material";
import {
  fetchDropDownCompaniesRequest
} from "store/actions/drop-down-companies-action";
import { useDispatch, useSelector } from "react-redux";
import sidePanelSvg from "assets/images/icons/sidepanel.svg";
import { selectUnifiedAccount } from "store/actions/unifield-account-action";

// project imports
import LogoSection from "../LogoSection";
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";
import AccountSelector from "./AccountSelector";

// assets
import { IconMenu2 } from "@tabler/icons";
import { display } from "@mui/system";
import Profile from "./Profile";
import { getUserEmailFromAccessToken } from "utils/clarity";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [name, setName] = useState();
  const { data, total_page, loading } = useSelector(
    (state) => state.dropDownCompanies
  );

  const selectedAccount = useSelector(
    (state) => state.account.selectedAccount
  );
 
   const handleAccountSelect =(account)=>{
    dispatch(selectUnifiedAccount(account))

   }
  const theme = useTheme();
  const location = useLocation()

  const routes = ["/order/orders", "/user", "/salesrep", "/company"];

  function doesRouteMatch(routeToCheck) {
    return routes.some(route => routeToCheck.startsWith(route));
  }

  useEffect(() => {
    const url = [];
    url.push(`page=${page}`);
    if (searchText.length) {
      url.push(`name=${searchText}`);
    }

    dispatch(
      fetchDropDownCompaniesRequest(
        `/customers/v2/companies/detail?${url.join("&")}`
      )
    );
  }, [page, searchText]);

  useEffect(() => {
    const user = getUserEmailFromAccessToken();
    if (user && user.name) {
      setName(user.name);
    } else {
      setName(null);
    }
  }, [])

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <ButtonBase sx={{ overflow: "hidden" }}>
          <img onClick={handleLeftDrawerToggle} src={sidePanelSvg} alt="btn" />
        </ButtonBase>
      </Box>

      {/* header search */}
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "end",
          paddingRight: "18px",
        }}
      >
        <AccountSelector
          accountData={data || []}
          disable={!doesRouteMatch(location.pathname)}
          setPage={setPage}
          setSearchText={setSearchText}
          loading={loading}
          total_page={total_page}
          page={page}
          onChange={handleAccountSelect}
          defaultSelected={selectedAccount}
        />{" "}
      </Box>
      <Profile name={name} account={selectedAccount}/>
      {/* notification & profile */}
      {/* <NotificationSection />
      <ProfileSection /> */}
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
