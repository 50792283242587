import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_IR_DETAILS_REQUEST,
  fetchIRDetailsSuccess,
  fetchIRDetailsFailure,
  FETCH_IR_DETAILS_SUCCESS,
} from "../actions/IRDetailsActions";
import { getRequest } from "api/apiCall";

// Saga to fetch data from the API
function* fetchIRDetailsFromApi(page) {
  try {
    const response = yield call(getRequest, `/customers/users-list?page=${page}`);
    if (response) {

      return response;
    } else {
      throw new Error("Failed to fetch IR Details: " + response.statusText);
    }
  } catch (error) {
    throw error;
  }
}

function* fetchIRDetailsSaga(action) {
  try {
    const { page } = action.payload;
    const data = yield call(fetchIRDetailsFromApi, page);
    yield put(fetchIRDetailsSuccess(data));
  } catch (error) {
    yield put(fetchIRDetailsFailure(error.message));
  }
}

function* irDetailsSaga() {
  yield takeEvery(FETCH_IR_DETAILS_REQUEST, fetchIRDetailsSaga);
}

export default irDetailsSaga;
