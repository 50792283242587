import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_CATALOG_REQUEST,
  fetchCatalogPriceSuccess,
  fetchCatalogPriceFailure
} from "../actions/fetchCatalogPriceAction";
import { getRequest } from "api/apiCall";
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

function* fetchDataFromApi(page) {
  try {
    const response = yield call(getRequest, `/data_syncs/v3/products/catalog/?parent_product__is_top_product=true&page_size=20&page=${page}`);
    if (response) {
      return response;
    } else {
      throw new Error("Failed to fetch catalog: " + response.statusText);
    }
  } catch (error) {
    captureCustomSentryEvent("fetchDataFromApi", error);
    throw error;
  }
}

function* fetchCatalogPriceSaga(action) {
  try {
    const data = yield call(fetchDataFromApi, action.payload.page);
   
    yield put(fetchCatalogPriceSuccess(data));
  }
  catch (error) {
    yield put(fetchCatalogPriceFailure(error.message));
  }
}

function* catalogSaga() {
  yield takeEvery(FETCH_CATALOG_REQUEST, fetchCatalogPriceSaga);
}

export default catalogSaga;
