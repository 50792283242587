import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

function AccountAssignedPopup({ open, handleClose, companies}) {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="scroll-dialog-title" className="dialog-title"><h3>Account Already Assigned</h3></DialogTitle>
        <DialogContent>
          {companies.map((company, index) =>
            company?.sales_rep_name ? (
              <p key={index}>
                <p><strong>{company?.name}{' '}</strong><span> is already assigned to  </span>{' '}<strong>{company?.sales_rep_name}.</strong></p>
              </p>
            ) : null
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" style={{ backgroundColor: "#0a2972", color: 'white' }} onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

export default AccountAssignedPopup