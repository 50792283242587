// Import any necessary dependencies
import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import {
    UPDATE_CUSTOMER_OPTION,
    UPDATE_DAILY_DATE_OPTION,  
    fetchDailySalesDetailsSuccess,
    fetchDailySalesDetailsFailure,
    fetchSalesRepsCustomersRecords,
    UPDATE_SUMMARY_VIEW,
    updateAnyState
} from '../actions/fetchSalesRepRecordsActions';
import {BASE_BACKEND_URL}  from '../constant';
import { toast } from 'react-toastify';

const updateCustomerOptionAPI = async (salesRepOption, salesRepCustomerOption, date, view) => {
    const response = await fetch(
        `${BASE_BACKEND_URL}/daily_sales_details/${salesRepOption}/${salesRepCustomerOption}?date=${date}&view=${view}`
    );

    if (!response.ok) {
        toast.error('Error fetching file details', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
        throw new Error('Error fetching file details');
    }

    const data = await response.json();
    return data;
};

function* updateCustomerOptionWorker(action) {
    try {
        const salesRepOption = yield select((state) => state.salesrep.salesRepOption);
        const salesRepCustomerOption = yield select((state) => state.salesrep.salesRepCustomerOption);
        const view = yield select((state) => state.salesrep.view);
        const date = yield select((state) => state.salesrep.DateOption);
        const report = yield select((state) => state.salesrep.report);
        
        if (salesRepCustomerOption !== '' && salesRepOption !== '' && report === 'detailed') {
            yield put(updateAnyState({'loading':true}));
            const data = yield call(updateCustomerOptionAPI, salesRepOption, salesRepCustomerOption, date, view);
            
            const totalSalePriceSum = data.reduce(
                (sum, item) => {
                const salePrice = parseFloat(item.total_sale_price);
                return sum + salePrice;
                },
                0
            );

            const grossProfitSum = data.reduce(
                (sum, item) => {
                const grossProfit = parseFloat(item.Gross_Profit || item.Gross_Profit);
                return sum + grossProfit;
                },
                0
            );
            yield put(fetchDailySalesDetailsSuccess({ 'data': data, 'totalDailySalePriceSum': totalSalePriceSum, 'dailyGrossProfitSum': grossProfitSum }));
        }
        
    } catch (error) {
        yield put(fetchDailySalesDetailsFailure(error.message));
    }
}

function* updateCustomerOptionWatcher() {
    yield takeLatest([UPDATE_CUSTOMER_OPTION, UPDATE_DAILY_DATE_OPTION, UPDATE_SUMMARY_VIEW], updateCustomerOptionWorker);
}
export default updateCustomerOptionWatcher;