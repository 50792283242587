import { postRequest, putRequest } from "api/apiCall";
import { isValid } from "date-fns";
export const validateFormData = (formData, setFormErrors) => {
  let isValid = true;
  const errors = {
    email: false,
    invalid_email: false,
    ir_password: false,
    password_length: false,
    confirm_password: false,
    username: false,
    first_name: false,
    last_name: false,
    phone_number: false,
    invalid_phone_number: false
  };

  // Check each field individually and set errors accordingly
  if (!formData.email) {
    errors.email = true;
    isValid = false;
  }
  if (!formData.email.endsWith("@bttnusa.com") || (formData.email?.includes(' '))) {
    errors.invalid_email = true;
    isValid = false;
  }
  if (formData.ir_password.length < 6) {
    errors.password_length = true;
    isValid = false;
  }
  if (!formData.ir_password) {
    errors.ir_password = true;
    isValid = false;
  }

  if (formData.ir_password && (!formData.confirm_password || formData.ir_password !== formData.confirm_password)) {
    errors.confirm_password = true;
    isValid = false;
  }

  if (!formData.ir_username || (formData.ir_username.includes(' '))) {
    errors.ir_username = true;
    isValid = false;
  }

  if (!formData.first_name || formData.first_name.length===0 || formData.first_name.trim('')==='') {
    errors.first_name = true;
    isValid = false;
  }

  if (!formData.last_name || formData.last_name.length===0 || formData.last_name.trim('')==='') {
    errors.last_name = true;
    isValid = false;
  }

  if (!validatePhoneNumber(formData.phone_number)) {
    errors.phone_number = true;
    isValid = false;
  } else errors.phone_number = false;

  setFormErrors(errors);
  return isValid;
};

export const handleFormChange = (event, formData, setFormData, formErrors, setFormErrors, setApiUsernameError, setApiEmailError, setOriginalPhoneNumber) => {
  const { name, value } = event.target;

  // Generate ir_username based on first name and last name
  if (name === "first_name" || name === "last_name") {
    if((value.length===0 || value.trim()===''))
      {
          setFormErrors({
        ...formErrors,
        [name]: true,
      });
    }
    else{
      setFormErrors({
        ...formErrors,
        [name]: false,
      });
    }
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    if (name === "first_name" || (name === "last_name")) {
      const username = generateUsername(
        updatedFormData.first_name,
        updatedFormData.last_name
      );
      updatedFormData.ir_username = username || ''; // Ensure username is set to empty string if generation fails
      setApiUsernameError(false)
    }

    setFormData(updatedFormData);

  }

   else {


    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear error for the current field if it is now valid
    if (name === "phone_number") {
      if (!validatePhoneNumber(value)) {
        setFormErrors({
          ...formErrors,
          [name]: true,
        });
      } else {
        setFormErrors({
          ...formErrors,
          [name]: false,
        });
        setFormData({
          ...formData,
          [name]: formatPhoneNumber(value),
        });
      }

    }
    else {

    if(name === "ir_username")
      {
        if(value.includes(' '))
        {
          setFormErrors({
            ...formErrors,
            [name]: true,
          });
        }
      }
      else{

      setFormErrors({
        ...formErrors,
        [name]: false,
      });


    }

    }
  }
  if (name === "ir_username") {
    setApiUsernameError(false)
  }
  if (name === "email") {
    setApiEmailError(false)
  }
};

export const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-numeric characters, including spaces
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');

  // Format based on the length of the cleaned phone number
  if (cleaned.length > 6) {
    // Format as 000-000-0000
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
  } else if (cleaned.length > 3) {
    // Format as 000-000
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}`;
  } else if (cleaned.length > 0) {
    // Format as 000
    return `${cleaned.slice(0, 3)}`;
  }

  return phoneNumber;
};



const generateUsername = (first_name, last_name) => {
  // Trim spaces from first_name and last_name
  const trimmedFirstName = first_name.replaceAll(' ','');
  const trimmedLastName = last_name.replaceAll(' ','');

  if (!trimmedFirstName && !trimmedLastName) {
    return '';
  }

  return `${trimmedFirstName.toLowerCase()}_${trimmedLastName.toLowerCase()}`;
};

export const handleEditIrForm = ( setLoading, additionalData, finalCompanyList, deletedCompanyList ,  setResponse, setSuccess,onFormSuccess,allCompanies) => {


    setLoading(true); // Start loading animation

    // Construct your request data
    let ir_edit_data = {
      email: additionalData.email,
      phone_number: `+1-${additionalData.phone_number}`,
      defaultCompanyId: additionalData.defaultCompanyId
    };
    if(finalCompanyList.length>0){ir_edit_data.company_ids=finalCompanyList}
    if(deletedCompanyList.length>0){ir_edit_data.deleted_company_ids= deletedCompanyList}
    putRequest('/customers/update-users', ir_edit_data)
      .then((res) => {
        setLoading(false); // Stop loading animation on response
        if (res.errors) {
          const apiErrors = res.errors;
        } else {
          setResponse(res);
          setSuccess(true);
          onFormSuccess(`+1-${additionalData.phone_number}`,allCompanies?.filter(company =>
              finalCompanyList.includes(company.id)));
        }

          setResponse(res);
          setSuccess(true);
      })
      .catch((error) => {
        setLoading(false); // Stop loading animation on error
      });
  };

export const handleSubmitForm = (event, setLoading, formData, setFormErrors, setApiEmailError, setApiUsernameError, setResponse, setSuccess,onFormSuccess) => {
  event.preventDefault();

  if (validateFormData(formData, setFormErrors)) {
    setLoading(true); // Start loading animation

    // Construct your request data
    let request_data = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      username: formData.ir_username,
      email: formData.email,
      password: formData.ir_password,
      confirm_password: formData.confirm_password,
      phone_number: "+1-" + formData.phone_number,
      role: formData.role,
      companies: formData.companies,
      defaultCompanyId: formData.defaultCompanyId,
    };

    // Make the API call using your postRequest function
    postRequest('/customers/add-users', request_data)
      .then((res) => {
        setLoading(false); // Stop loading animation on response
        if (res.errors) {
          const apiErrors = res.errors;
          if (apiErrors) {
            // Map API errors to form errors
            setApiEmailError(apiErrors.email ? true : false);
            setApiUsernameError(apiErrors.username ? true : false);
          }
        } else {
          setApiEmailError(false);
          setApiUsernameError(false);
          setResponse(res);
          setSuccess(true);
        }
      })
      .catch((error) => {
        setLoading(false); // Stop loading animation on error

      });
  }
};
export const validatePhoneNumber = (value) => {
  const cleaned_value = value?.replaceAll("-", "")
  const validate_value = /^[0-9]+$/.test(cleaned_value);
  return validate_value && cleaned_value.length === 10
}
// Custom styles for react-select dropdown
export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#1976d2" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: state.isSelected ? "#1976d2" : "#e3f2fd",
      color: state.isSelected ? "white" : "black",
    },
  }),
  control: (provided) => ({
    ...provided,
    width: "100%",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "white",
    backgroundColor: "#1976d2",
  }),
  multiValueRemove: (provided, state) => {
    const label = state.data.label;
    return {
      ...provided,
      color: label === 'CoolKurt' ? 'white' : 'white',
      backgroundColor: label === 'CoolKurt' ? '#1976d2' : '#1976d2',
      display: label === 'CoolKurt' ? 'none' : 'inline-flex',
      "&:hover": {
        backgroundColor: "#1976d2",
        color: "white",
      },
    };
  },
};

export const isFormDataEmpty = (formData) => {
  for (const key in formData) {
    const value = formData[key]
    if ((value!=="" && String(value).trim()!=="") && key!=="role" && key!=="companies" && key!=="defaultCompanyId" && key!=="confirm_password") {
      return false; // Return false if any field is not empty
    }
  }
  return true; // Return true if all fields are empty
};
