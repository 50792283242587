// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import {useSelector} from "react-redux";
import NavItem from './NavItem';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { isAuthenticated,roles } = useSelector((state) => state.authentication);
  const navItems = menuItem.items.map((item) => {
   
    if (item.id === 'salesrep') {
      if (roles?.includes('Independent Representative')) {
        item.children = item.children.filter(childItem => childItem.id === 'irs_dashboard');
      } else {
        item.children = item.children.filter(childItem => childItem.id !== 'irs_dashboard');
      }
    } else if(item.id === 'productIntake' && roles?.includes('Independent Representative')) {
      return null;
    } else if ((item.id === 'salesrep' || item.id ==='ir-details') && !(roles?.includes('Bttn Engineering') || roles?.includes('Sales Representative'))){ return null;}
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      case 'item':
        return <NavItem key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
