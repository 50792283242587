import { BASE_DATA_COTTAGE_BACKEND_URL } from "store/constant";
import {
  refreshTokenFailure,
  refreshTokenRequest,
  refreshTokenSuccess,
} from "../store/actions/fetchAuthAction";
import { store } from "../store";

export const getRequest = async (URL) => {
  const createHeaders = (attempt = 0) => ({
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    "x-attempt": attempt,
  });

  let headers = createHeaders();
  headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  try {
    let data = await fetch(`${BASE_DATA_COTTAGE_BACKEND_URL}${URL}`, {
      headers,
    });

    const { dispatch } = store;
    if (data.status === 401) {
      dispatch(refreshTokenRequest());
      // Perform token refresh operation, replace this with your actual refresh token logic
      const refreshedToken = await refreshToken();
      dispatch(refreshTokenSuccess(refreshedToken));
    }
    return data.json();
  } catch (error) {
    const refreshToken = localStorage.getItem("refreshToken");
    if (error.response && error.response.status === 401 && refreshToken) {
      const refreshResponse = await refreshToken();
      if (refreshResponse.status === 200) {
        localStorage.setItem("accessToken", refreshResponse.access);
        headers = createHeaders(1);
        return await fetch(`${BASE_DATA_COTTAGE_BACKEND_URL}${URL}`, {
          headers,
        });
      }
    } else {
      throw error;
    }
  }
};

export const loginRequest = async (URL, data) => {
  const createHeaders = (attempt = 0) => ({
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    "x-attempt": attempt,
  });
  let headers = createHeaders();
  headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${BASE_DATA_COTTAGE_BACKEND_URL}${URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const responseError = {
        type: "Error",
        message: response.message || "Something went wrong",
        data: (await response.json()) || "",
        code: response.status || "",
      };

      const error = new Error();
      error.info = responseError;
      throw error;
    }
    return response.json();
  } catch (error) {
    return error;
  }
};

export const postRequest = async (URL, data) => {
  const createHeaders = (attempt = 0) => ({
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    "x-attempt": attempt,
  });
  let headers = createHeaders();
  headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const token = localStorage.getItem("accessToken");
  try {
    const response = await fetch(`${BASE_DATA_COTTAGE_BACKEND_URL}${URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const { dispatch } = store;
    if (data.status === 401) {
      dispatch(refreshTokenRequest());
      // Perform token refresh operation, replace this with your actual refresh token logic
      const refreshedToken = await refreshToken();
      dispatch(refreshTokenSuccess(refreshedToken));
    }
    return response.json();
  } catch (error) {
    const refreshToken = localStorage.getItem("refreshToken");
    if (error.response && error.response.status === 401 && refreshToken) {
      const refreshResponse = await refreshToken();
      if (refreshResponse.status === 200) {
        localStorage.setItem("accessToken", refreshResponse.access);
        headers = createHeaders(1);
        return await fetch(`${BASE_DATA_COTTAGE_BACKEND_URL}${URL}`, {
          headers,
        });
      }
    } else {
      throw error;
    }
  }
};

export const postRequestWithStatusResponse = async (URL, data) => {
  const createHeaders = (attempt = 0) => ({
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    "x-attempt": attempt,
  });

  let headers = createHeaders();
  headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(`${BASE_DATA_COTTAGE_BACKEND_URL}${URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    const { dispatch } = store;

    if (response.status === 401) {
      dispatch(refreshTokenRequest());
      const refreshedToken = await refreshToken();
      dispatch(refreshTokenSuccess(refreshedToken));
    }

    // Attempt to parse the response as JSON
    let responseData;
    try {
      responseData = await response.json();
    } catch (e) {
      // If parsing fails, use an empty object
      responseData = {};
    }

    // Attach status and statusText to the response data
    responseData.status = response.status;
    responseData.statusText = response.statusText;

    return responseData;
  } catch (error) {
    // Handle network errors or other exceptions
    // Return a consistent JSON object with error details
    return {
      status: error.status || 500,
      statusText: error.statusText || "Internal Server Error",
      message: error.message || "An unexpected error occurred",
    };
  }
};

const refreshToken = async () => {
  const token = localStorage.getItem("refreshToken");
  const { dispatch } = store;
  try {
    const response = await fetch(
      `${BASE_DATA_COTTAGE_BACKEND_URL}${"/api/token/refresh"}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh: token || "" }),
      }
    );
    if (response.status === 401) {
      dispatch(refreshTokenFailure(response));
    } else {
      return response.json();
    }
  } catch (error) {
    dispatch(refreshTokenFailure(error));
    return error;
  }
};

export const deleteRequest = async (URL, bodyData) => {
  const createHeaders = (attempt = 0) => ({
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    "x-attempt": attempt,
  });

  let headers = createHeaders();
  headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "Content-Type": "application/json", // Specify content type as JSON if sending body
  };

  try {
    let response = await fetch(`${BASE_DATA_COTTAGE_BACKEND_URL}${URL}`, {
      method: "DELETE",
      headers,
      body: JSON.stringify(bodyData), // Convert bodyData to JSON string if provided
    });

    const { dispatch } = store;
    if (response.status === 401) {
      dispatch(refreshTokenRequest());
      // Perform token refresh operation, replace this with your actual refresh token logic
      const refreshedToken = await refreshToken();
      dispatch(refreshTokenSuccess(refreshedToken));
    }
    return response.json();
  } catch (error) {
    const refreshToken = localStorage.getItem("refreshToken");
    if (error.response && error.response.status === 401 && refreshToken) {
      const refreshResponse = await refreshToken();
      if (refreshResponse.status === 200) {
        localStorage.setItem("accessToken", refreshResponse.access);
        headers = createHeaders(1);
        return await fetch(`${BASE_DATA_COTTAGE_BACKEND_URL}${URL}`, {
          method: "DELETE",
          headers,
          body: JSON.stringify(bodyData), // Convert bodyData to JSON string if provided
        });
      }
    } else {
      throw error;
    }
  }
};

export const putRequest = async (URL, data) => {
  const createHeaders = (attempt = 0) => ({
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "x-attempt": attempt,
  });

  let headers = createHeaders();

  const token = localStorage.getItem("accessToken");

  try {
    const response = await fetch(`${BASE_DATA_COTTAGE_BACKEND_URL}${URL}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    const { dispatch } = store;
    if (response.status === 401) {
      dispatch(refreshTokenRequest());
      // Perform token refresh operation, replace this with your actual refresh token logic
      const refreshedToken = await refreshToken();
      dispatch(refreshTokenSuccess(refreshedToken));
    }

    return response.json();
  } catch (error) {
    const refreshToken = localStorage.getItem("refreshToken");
    if (error.response && error.response.status === 401 && refreshToken) {
      const refreshResponse = await refreshToken();
      if (refreshResponse.status === 200) {
        localStorage.setItem("accessToken", refreshResponse.access);
        headers = createHeaders(1);
        return await fetch(`${BASE_DATA_COTTAGE_BACKEND_URL}${URL}`, {
          method: "PUT",
          headers,
          body: JSON.stringify(data),
        });
      }
    } else {
      throw error;
    }
  }
};
