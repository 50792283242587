import { takeLatest, call, put } from "redux-saga/effects";
import {
  FETCH_CATALOG_PRICE_REQUEST,
  fetchCatalogPriceSuccess,
  fetchCatalogPriceFailure,
} from "../actions/catalogFilterActions";
import { getRequest } from "api/apiCall";
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

function* fetchCatalogPrice(action) {
  try {
    const { priceList, accountName } = action.payload;
    let url;
    if (!accountName) {
      url = `/data_syncs/products/catalog/?price_list_name=${priceList}`;
    } else {
      url = `/data_syncs/products/catalog/?price_list_name=${priceList}&customer_group_name=${accountName}`;
    }

    const response = yield call(getRequest, url);

    yield put(fetchCatalogPriceSuccess(response.data));
  } catch (error) {
    captureCustomSentryEvent("fetchCatalogPrice", error);
    yield put(fetchCatalogPriceFailure(error));
  }
}

function* catalogPriceSaga() {
  yield takeLatest(FETCH_CATALOG_PRICE_REQUEST, fetchCatalogPrice);
}

export default catalogPriceSaga;
