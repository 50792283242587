import React, { useEffect, useState, useRef } from "react";
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Box,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { useLocation } from "react-router";
import DisableDropDownInput from "./disable-drop-down";
import { styled } from "@mui/material/styles";
import ModelSearchBar from "./search-icon-input"; // Assuming you have a ModelSearchBar component for search input
import DropDownInput from "./dropdown-input";
const SearchContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: "1px solid #B2B3B5",
}));

const ListContainer = styled(Box)(({ theme }) => ({
  maxHeight: "50vh",
  overflowY: "auto",
  padding: 0,
}));

const StyledListItem = styled(ListItem)(({ theme, isSelected, highLight }) => ({
  backgroundColor: isSelected
    ? "rgba(33, 150, 243, 0.08)"
    : highLight
    ? "#cdd5df"
    : "transparent",
  "&:hover": {
    backgroundColor: "#e3e8ef",
  },
  "& .MuiTypography-root": {
    color: "#0a2972",
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  "& .MuiTypography-root": {
    color: "#0a2972",
  },
}));

function AccountSelector({
  accountData,
  defaultSelected,
  onChange,
  page,
  setPage,
  setSearchText,
  loading,
  disable,
  total_page,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [searchText, setSearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const listRef = useRef(null);
  const containerRef = useRef(null);
  useEffect(() => {
    if (defaultSelected) {
      setSelectedValue(defaultSelected);
      const defaultIndex = accountData.findIndex(
        (option) => option.name === defaultSelected
      );
      setHighlightedIndex(defaultIndex);
    }
  }, [defaultSelected, accountData]);

  useEffect(() => {
    if (popoverOpen) {
      const defaultIndex = accountData.findIndex(
        (option) => option.name === selectedValue
      );
      setHighlightedIndex(defaultIndex);
    }
  }, [popoverOpen, accountData, selectedValue]);

  const handleOpenPopover = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
    const defaultIndex = accountData.findIndex(
      (option) => option.name === selectedValue
    );
    setHighlightedIndex(defaultIndex);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setPopoverOpen(false);
    setSearchText("");
    setPage(1);
  };

  const handleOptionClick = (option) => {
    setSelectedValue(option.name);
    onChange(option);
    handleClosePopover();
  };

  const handleKeyDown = (event) => {
    if (!popoverOpen) return;

    if (event.key === "ArrowDown") {
      event.preventDefault();
      setHighlightedIndex((prevIndex) => {
        const nextIndex =
          prevIndex < accountData.length - 1 ? prevIndex + 1 : 0;
        scrollToHighlightedItem(nextIndex);
        return nextIndex;
      });
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setHighlightedIndex((prevIndex) => {
        const nextIndex =
          prevIndex > 0 ? prevIndex - 1 : accountData.length - 1;
        scrollToHighlightedItem(nextIndex);
        return nextIndex;
      });
    } else if (event.key === "Enter") {
      if (highlightedIndex >= 0 && highlightedIndex < accountData.length) {
        handleOptionClick(accountData[highlightedIndex]);
      }
    }
  };

  const scrollToHighlightedItem = (index) => {
    const contRent = containerRef.current;
    const list = listRef.current;
    const item = list.children[index];

    const itemHeight = item?.clientHeight;
    const listHeight = contRent?.clientHeight;
    const scrollTop = contRent?.scrollTop;
    const offsetTop = item?.offsetTop;

    if (offsetTop + itemHeight > scrollTop + listHeight) {
      contRent.scrollTop = offsetTop + itemHeight - listHeight;
    } else if (offsetTop < scrollTop) {
      contRent.scrollTop = offsetTop;
    }
  };

  // const filteredOptions = accountData.filter((option) =>
  //   option.name.toLowerCase().includes(searchText.toLowerCase())
  // );

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (
      scrollTop + clientHeight +2 >= scrollHeight &&
      !loading &&
      total_page > page
    ) {
      setPage((old) => old + 1);
    }
  };

  return (
    <FormControl
      onKeyDown={handleKeyDown}
      label="Accounts"
      variant="outlined"
      fullWidth
      sx={{ width: "300px" }}
    >
      {disable ? (
        <DropDownInput
          handleKeyDown={handleOpenPopover}
          open={popoverOpen}
          setOpen={setPopoverOpen}
          selectedValue={selectedValue}
        />
      ) : (
        <DisableDropDownInput />
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box width={300}>
          <SearchContainer>
            <ModelSearchBar
              setSearchText={setSearchText}
              setPage={setPage}
              loading={loading}
            />
          </SearchContainer>
          <ListContainer onScroll={handleScroll} ref={containerRef}>
            <List ref={listRef}>
              {accountData.length ? (
                accountData.map((option, index) => (
                  <StyledListItem
                    button
                    key={index}
                    isSelected={selectedValue === option.name}
                    style={{
                      padding: "5px 16px",
                    }}
                    onClick={() => handleOptionClick(option)}
                    highLight={highlightedIndex === index}
                  >
                    <StyledListItemText primary={option.name} />
                  </StyledListItem>
                ))
              ) : (
                <ListItem>
                  <StyledListItemText primary={"No Account found"} />
                </ListItem>
              )}
            </List>
          </ListContainer>
        </Box>
      </Popover>
    </FormControl>
  );
}

export default AccountSelector;
