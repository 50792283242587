// Import any necessary dependencies
import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import {
    UPDATE_SUMMARY_VIEW,
    FETCH_SALES_REP_CUSTOMERS_RECORDS,
    fetchSalesRepsCustomersRecordsSuccess,
    fetchSalesRepsCustomersRecordsFailure,
    updateAnyState
} from '../actions/fetchSalesRepRecordsActions';
import {BASE_BACKEND_URL}  from '../constant';
import { toast } from 'react-toastify';

const fetchSalesRepCustomersAPI = async (salesRepOption) => {
    const response = await fetch(
        `${BASE_BACKEND_URL}/sales_rep_customers/${salesRepOption}`
    );

    if (!response.ok) {
        toast.error('Error fetching file details', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
        throw new Error('Error fetching file details');
    }

    const data = await response.json();
    return data;
};

function* fetchSalesRepCustomersWorker(action) {
    try {
        const salesRepOption = yield select((state) => state.salesrep.salesRepOption);
        const report = yield select((state) => state.salesrep.report);
        if (salesRepOption !== '') {//&& report === 'summary'
            yield put(updateAnyState({'loading':true}));
            const data = yield call(fetchSalesRepCustomersAPI, salesRepOption);
            yield put(fetchSalesRepsCustomersRecordsSuccess(data));
        } else { 
            yield put(updateAnyState({loading:false}));

        }        
    } catch (error) {
        yield put(fetchSalesRepsCustomersRecordsFailure(error.message));
    }
}

function* fetchSalesRepCustomersWatcher() {
    yield takeLatest([FETCH_SALES_REP_CUSTOMERS_RECORDS], fetchSalesRepCustomersWorker);
}
export default fetchSalesRepCustomersWatcher;