import React from "react";
import { TableRow, TableCell, Box, Checkbox, IconButton, DialogTitle } from "@mui/material";
import { blue } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./IRTable.module.css";

const TableRowDetails = ({ row, index, handleRowClick }) => {
  const companies = [...row.companies];
  const coolKurtExists = companies.some((company) => company.name === "CoolKurt");

  if (!coolKurtExists) {
    companies.push({ id: "coolKurt", name: "CoolKurt" });
  }

  return (
    <TableRow key={"trdetails" + index}>
      <TableCell colSpan={4} className={styles.detailsSection}>
        <DialogTitle
          id="scroll-dialog-title"
          className={styles.dialogTitle}
          sx={{ paddingBottom: 0 }}
        >
          <Box display="flex" justifyContent="space-between"  sx={{ marginBottom: 1 }}>
            <h2 style={{ margin: 0 }}>{row.name}</h2>
            <IconButton aria-label="close" onClick={() => handleRowClick(row.id)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box sx={{ marginTop:0,paddingLeft:3 }}>
          <p style={{ color: "#a0a0a0", marginBottom: "10px",marginTop:0 }}>
            Created on {row.date_created_at} at {row.time_created_at}
            {row.created_by && (
              <span>
                {" "}
                by <strong>{row.created_by}</strong>
              </span>
            )}
          </p>
          <strong>ASSOCIATED ACCOUNTS:</strong>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {companies.map((company, idx) => (
              <li
                key={`company_id_${idx}`+index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={true}
                  disableRipple
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    color: blue[500],
                    "&.Mui-checked": {
                      color: blue[500],
                    },
                    pointerEvents: "none",
                  }}
                />
                {company.name}
              </li>
            ))}
          </ul>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableRowDetails;
