export const FETCH_SHOPPING_CART_ITEMS_REQUEST = "FETCH_SHOPPING_CART_ITEMS_REQUEST";
export const FETCH_SHOPPING_CART_ITEMS_SUCCESS = "FETCH_SHOPPING_CART_ITEMS_SUCCESS";
export const FETCH_SHOPPING_CART_ITEMS_FAILURE = "FETCH_SHOPPING_CART_ITEMS_FAILURE";

export const fetchShoppingCartItems = (shoppingCartId, companyId, page) => ({
    type: FETCH_SHOPPING_CART_ITEMS_REQUEST,
    payload: { shoppingCartId, companyId, page }
});

export const fetchShoppingCartItemsSuccess = (data) => ({
    type: FETCH_SHOPPING_CART_ITEMS_SUCCESS,
    payload: { data }
});

export const fetchShoppingCartItemsFailure = (error) => ({
    type: FETCH_SHOPPING_CART_ITEMS_FAILURE,
    payload: { error }
});
