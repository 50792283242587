import React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SearchIcon from "@mui/icons-material/Search";
import lock from 'assets/images/icons/lock.svg'

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  backgroundColor: "#E3E8EF",
  cursor:"not-allowed",

  border: "1px solid #00000040",
  borderRadius: "4px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));



const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#121926",
  placeholder:"#697586",
  width: "100%",
  cursor:"not-allowed",
  "& .MuiInputBase-input": {
    padding: "15px 14px",
    cursor:"not-allowed",
    width: "100%",
    "&::placeholder": {
      color: "#697586",
      opacity: 1, // Ensures the placeholder text is fully visible
    },
    // paddingLeft: "50px",
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  "& .MuiInputBase-root": {
    width: "100%",
  },
}));

function DisableDropDownInput({  }) {

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        gap: "15px",
        height: 38,
        width: "300px",
      }}
    >
      <Search style={{ width: "100%" ,cursor:"not-allowed"}} >
        <StyledInputBase
       
          placeholder={`Account selection not available`}
          inputProps={{ "aria-label": "Search Account", readOnly: true }}
          sx={{ color: "white", display: "flex" ,paddingRight:"15px"}}
          endAdornment={
            <InputAdornment position="end">
               <img src={lock} alt="ii"/>
            </InputAdornment>
          }
        />
      </Search>
    </div>
  );
}

export default DisableDropDownInput;
