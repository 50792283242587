// assets
import {IconDashboard, IconListDetails, IconPercentage, IconShoppingBag, IconFileUpload} from '@tabler/icons';

// constant

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const product = {
  id: 'product',
  title: 'Product',
  type: 'group',
  children: [
    {
      id: 'price_lists',
      title: 'Catalog',
      type: 'item',
      url: '/product/price-lists',
      icon: IconListDetails,
      breadcrumbs:false
    },
    {
      id: 'shopping_lists',
      title: 'Shopping List',
      type: 'item',
      url: '/product/shopping-lists',
      icon: IconShoppingBag,
      breadcrumbs:false
    },
  
    {
      id: 'Bulk-uploads',
      title: 'Bulk Uploads',
      type: 'item',
      url: '/product/bulk-uploads',
      icon: IconFileUpload,
      breadcrumbs:false
    },

  ]
};

export default product;
