import {
    FETCH_IR_DETAILS_REQUEST,
    FETCH_IR_DETAILS_SUCCESS,
    FETCH_IR_DETAILS_FAILURE,
} from "../actions/IRDetailsActions";

const initialState = {
    data: [],
    loading: false,
    error: null,
    total_records: 0,
    hasNextPage: false,
};

const IRDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_IR_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_IR_DETAILS_SUCCESS:
            {
                var newData = (action.payload.data["previous"] == null && action.payload.data.count != null) ? action.payload.data.results : [...state.data, ...action.payload.data.results]
                return {
                    ...state,
                    data: newData,
                    total_records: action.payload.data.count == null ? state.total_records : action.payload.data.count,
                    hasNextPage: newData.count != state.total_records && action.payload.data.next != null,
                    loading: false,
                    error: null,
                };    
            }
        case FETCH_IR_DETAILS_FAILURE:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
};

export default IRDetailsReducer;
