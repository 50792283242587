export const FETCH_SHOPPING_CART_REQUEST = 'FETCH_SHOPPING_CART_REQUEST';
export const FETCH_SHOPPING_CART_SUCCESS = 'FETCH_SHOPPING_CART_SUCCESS';
export const FETCH_SHOPPING_CART_FAILURE = 'FETCH_SHOPPING_CART_FAILURE';

export const fetchShoppingCartRequest = (companyId) => ({
  type: FETCH_SHOPPING_CART_REQUEST,
  payload: { companyId },
});

export const fetchShoppingCartSuccess = (data) => ({
  type: FETCH_SHOPPING_CART_SUCCESS,
  payload: { data },
});

export const fetchShoppingCartFailure = (error) => ({
  type: FETCH_SHOPPING_CART_FAILURE,
  payload: { error },
});