// sagas.js
import { call, put, takeLatest } from 'redux-saga/effects';

import { loginRequest, postRequest } from 'api/apiCall';
import { FETCH_LOGIN_REQUEST, fetchLoginRequestFailure, fetchLoginRequestSuccess } from "../actions/fetchAuthAction";


// API URL
const apiUrl = '/api/token';

// API Request Function
function* fetchLoginAPI(payload) {
  try {
    const response = yield call(loginRequest, apiUrl, payload);
    if (response) {
      return response;
    } else {
      throw new Error("Failed to fetch login: " + response);
    }
  } catch (error) {
    throw error;
  }
}

// Worker Saga
function* fetchLogin(action) {
  try {
    const response = yield call(fetchLoginAPI, action.payload);
    if (response?.info?.code > 400) {
      yield put(fetchLoginRequestFailure(response.info.data));
    } else {
      yield put(fetchLoginRequestSuccess(response));
    }
  } catch (error) {
    yield put(fetchLoginRequestFailure(error));
  }
}

// Watcher Saga
function* watchFetchLogin() {
  yield takeLatest(FETCH_LOGIN_REQUEST, fetchLogin);
}

export default watchFetchLogin;
