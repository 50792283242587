import { postRequest, getRequest } from "api/apiCall";

export const getScrapingStatus = (
  company_id,
  apiWillStart,
  onSuccess,
  onError
) => {
  let url = `/data_syncs/pricing/status?company_id=${company_id}`;
  apiWillStart();
  getRequest(url)
    .then((resp) => {
      onSuccess(resp);
    })
    .catch((err) => {
      onError(err);
    });
};

export const startScraping = (
  company_id,
  flag,
  apiWillStart,
  cbSuccess,
  cbError
) => {
  let url = `/data_syncs/pricing/scrap_all_variants_on_demand`;
  apiWillStart();
  postRequest(url, { company_id: company_id, flag: flag })
    .then((res) => {
      cbSuccess(res);
    })
    .catch((error) => {
      cbError(error);
    });
};

export const stopScraping = (company_id, apiWillStart, cbSuccess, cbError) => {
  let url = `/data_syncs/pricing/stop_scrapping`;
  apiWillStart();
  postRequest(url, { company_id: company_id })
    .then((res) => {
      cbSuccess(res);
    })
    .catch((error) => {
      cbError(error);
    });
};
