import {
  FETCH_ORDER_DETAILS_REQUEST,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_FAILURE,
} from "../actions/fetchOrderDetailsActions";

const initialState = {
  accountData: {},
  productData: [],
  loading: false,
  hasNext: false,
  productLoading: false,
  error: null,
  count:0
};

const orderDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER_DETAILS_REQUEST:
    
      if (action.payload.page !== 1) {
        return { ...state, productLoading: true, error: null };
      }
      return { ...state, loading: true, error: null };
    case FETCH_ORDER_DETAILS_SUCCESS:
      if (action.payload.data.previous !== null) {
     
        return {
          ...state,
          productLoading: false,
          productData: [
            ...state.productData,
            ...action.payload.data.results.order_items.results,
          ],
          hasNext: action.payload.data.next !== null,
          error: null,
        };
      }

      return {
        ...state,
        loading: false,
        accountData: action.payload.data.results.order_details,
        productData: action.payload.data.results.order_items.results,
        hasNext: action.payload.data.next !== null,
        error: null,
        count:action.payload.data.count
      };
    case FETCH_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        productLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default orderDetailsReducer;
