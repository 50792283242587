// Import any necessary dependencies
import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import {
    UPDATE_SALES_REP_OPTION,
    UPDATE_SUMMARY_VIEW,
    UPDATE_DAILY_DATE_OPTION,
    fetchDailySalesSummarySuccess,
    fetchDailySalesSummaryFailure,
    fetchSalesRepsCustomersRecords,
    updateAnyState,
} from '../actions/fetchSalesRepRecordsActions';
import {BASE_BACKEND_URL}  from '../constant';
import { toast } from 'react-toastify';

const updatesalesRepOptionAPI = async (salesRepOption, date, view) => {
    const response = await fetch(
        `${BASE_BACKEND_URL}/daily_sales_summary/${salesRepOption}?date=${date}&view=${view}`
    );

    if (!response.ok) {
        toast.error('Error fetching file details', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });
        throw new Error('Error fetching file details');
    }

    const data = await response.json();
    return data;
};

function* updatesalesRepOptionWorker(action) {
    try {
        const salesRepOption = yield select((state) => state.salesrep.salesRepOption);
        const view = yield select((state) => state.salesrep.view);
        const date = yield select((state) => state.salesrep.DateOption);
        const report = yield select((state) => state.salesrep.report);
        if (salesRepOption !== '' && report === 'summary') {
            yield put(updateAnyState({'loading':true}));
            const data = yield call(updatesalesRepOptionAPI, salesRepOption, date, view);
            
            const totalSalePriceSum = data.reduce(
                (sum, item) => {
                const salePrice = parseFloat(item.total_sale_price);
                return sum + salePrice;
                },
                0
            );

            const grossProfitSum = data.reduce(
                (sum, item) => {
                const grossProfit = parseFloat(item.Gross_Profit || item.Gross_Profit);
                return sum + grossProfit;
                },
                0
            );
            yield put(updateAnyState({'dailySalesDetails':[], 'dailySalesSummary':[]}));// 'salesRepCustomerOption':''
            yield put(fetchDailySalesSummarySuccess({ 'data': data, 'totalSalePriceSum': totalSalePriceSum, 'grossProfitSum': grossProfitSum }));
        }
        else if (salesRepOption !== '') {
            yield put(updateAnyState({'loading':true}));
            yield put(fetchSalesRepsCustomersRecords());
        }
        
        
    } catch (error) {
        yield put(fetchDailySalesSummaryFailure(error.message));
    }
}

function* updatesalesRepOptionWatcher() {
    yield takeLatest([UPDATE_SALES_REP_OPTION, UPDATE_DAILY_DATE_OPTION, UPDATE_SUMMARY_VIEW], updatesalesRepOptionWorker);
}
export default updatesalesRepOptionWatcher;