
export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';
export const FETCH_COMPANY_LIST_EMPTY = 'FETCH_COMPANY_LIST_EMPTY';

export const fetchCompaniesRequest = (url) => ({
    type: FETCH_COMPANIES_REQUEST,
    payload:{url}
  });
  export const fetchCompaniesEmpty = () => ({
    type: FETCH_COMPANY_LIST_EMPTY,
   
  }); 
  export const fetchCompaniesSuccess = (data) => ({
    type: FETCH_COMPANIES_SUCCESS,
    payload: { data },
  });
  
  export const fetchCompaniesFailure = (error) => ({
    type: FETCH_COMPANIES_FAILURE,
    payload: { error },
  });
  