import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_DOCUMENTS_REQUEST,
  GENERATE_VIEW_PRESIGNED_URL_REQUEST,
  SET_HAS_MORE_PAGES,
  fetchDocumentsSuccess,
  fetchDocumentsFailure,
  generateViewPresignedUrlSuccess,
  generateViewPresignedUrlFailure,
} from "store/actions/accountRecordsAction";
import { postRequest } from "api/apiCall";
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

function* fetchDocuments(action) {
    try {
      const { selectedCompanyID, page } = action.payload;
      const payload = {
        company_id: selectedCompanyID,
        document_type: 'DOCUMENT',
      };

      const res = yield call(postRequest, `/customers/documents/all?page=${page}&size=20`, payload);
      if (res.error) {
        captureCustomSentryEvent('fetchDocuments', res.error);
        yield put({ type: SET_HAS_MORE_PAGES, payload: false });
      } else {
        yield put(fetchDocumentsSuccess(res));
        yield put({ type: SET_HAS_MORE_PAGES, payload: res.page < res.pages });
      }
    } catch (error) {
      yield put({ type: SET_HAS_MORE_PAGES, payload: false });
      yield put(fetchDocumentsFailure(error));
    }
  }

  function* generateViewPresignedUrl(action) {
    try {
      const { payload } = action;
      const res = yield call(postRequest, '/customers/documents/generate-view-presigned-url', payload);
      yield put(generateViewPresignedUrlSuccess(res.download_url));
      window.open(res.download_url);
    } catch (error) {
      captureCustomSentryEvent('generateViewPresignedUrl', error);
      yield put(generateViewPresignedUrlFailure(error));
    }
  }

  function* accountRecordsSaga() {
    yield takeLatest(FETCH_DOCUMENTS_REQUEST, fetchDocuments);
    yield takeLatest(GENERATE_VIEW_PRESIGNED_URL_REQUEST, generateViewPresignedUrl);
  }

  export default accountRecordsSaga;
