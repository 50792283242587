export const FETCH_ORDER_DETAILS_REQUEST = "FETCH_ORDER_DETAILS_REQUEST";
export const FETCH_ORDER_DETAILS_SUCCESS = "FETCH_ORDER_DETAILS_SUCCESS";
export const FETCH_ORDER_DETAILS_FAILURE = "FETCH_ORDER_DETAILS_FAILURE";


export const fetchOrderDetails = (orderId,page) => ({
    type: FETCH_ORDER_DETAILS_REQUEST,
    payload: { orderId , page}
  });
  
  export const fetchOrderDetailsSuccess = (data) => ({
    type: FETCH_ORDER_DETAILS_SUCCESS,
    payload: { data }
  });
  
  export const fetchOrderDetailsFailure = (error) => ({
    type: FETCH_ORDER_DETAILS_FAILURE,
    payload: { error }
  });
  