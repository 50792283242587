export const FETCH_CATALOG_REQUEST = "FETCH_CATALOG_REQUEST";
export const FETCH_CATALOG_PRICE_SUCCESS = "FETCH_CATALOG_PRICE_SUCCESS";
export const FETCH_CATALOG_PRICE_SUCCESS1= "FETCH_CATALOG_PRICE_SUCCESS_LOADING";
export const FETCH_CATALOG_PRICE_FAILURE = "FETCH_CATALOG_PRICE_FAILURE";


export const fetchCatalogPrice = (page) => ({
  type: FETCH_CATALOG_REQUEST,
  payload: { page}
});

export const fetchCatalogPriceSuccess = (data) => ({
  type: FETCH_CATALOG_PRICE_SUCCESS,
  payload: { data },
});

export const fetchCatalogPriceSuccess1 = (data) => ({
  type: FETCH_CATALOG_PRICE_SUCCESS1,
  payload: { data },
});

export const fetchCatalogPriceFailure = (error) => ({
  type: FETCH_CATALOG_PRICE_FAILURE,
  payload: { error },
});

