import React, { useState } from "react";
import TableContainer from "./TableContainer";
import TableRowDetails from "./TableRowDetails";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import styles from "./IRTable.module.css"; // Import CSS module
import { fetchIRDetailsRequest } from "store/actions/IRDetailsActions";
import { fetchCompanyListRequest } from "store/actions/fetchCompaniesAction";
import { connect } from "react-redux";
import IRForm from "./IRForm";

function IRTable({
  data,
  currentPage,
  setCurrentPage,
  loading,
  containerRef,
  hasNext,
  companyListData,
  fetchIRDetailsRequest,
  fetchCompanyListRequest
}) {
  const [expandedRows, setExpandedRows] = useState([]);
  const [isIRFormOpen, setIsIRFormOpen] = useState(false);
  const [currentSelectedRow, setCurrentSelectedRow] = useState();

  const handleOpenUpdateIRView = (row) => {
    setIsIRFormOpen(true);
    setCurrentSelectedRow(row);
  }

  const handleRowClick = (rowId) => {
    setExpandedRows((prev) =>
      prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]
    );
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    if (hasNext && scrollTop + clientHeight +3 >= scrollHeight) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const onFormSuccess = (phoneNumber,companies) => {
    const updatedRow = currentSelectedRow;
    updatedRow.phone_number = phoneNumber;
    updatedRow.companies = companies;
    setCurrentSelectedRow(updatedRow);
  }

  const defaultCompanyId = companyListData?.find(company => company.company_name === "CoolKurt")?.company_id;
  return (
    <>
    {isIRFormOpen && !!currentSelectedRow ? <IRForm onFormSuccess={onFormSuccess} open={isIRFormOpen} setOpen={setIsIRFormOpen} companyListData={companyListData} defaultCompanyId={defaultCompanyId} userDetails={currentSelectedRow}/>: <></>}
    <TableContainer
      containerRef={containerRef}
      handleScroll={handleScroll}
      currentPage={currentPage}
      hasNext={hasNext}
    >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell className={styles.stickyHeader}>Name</TableCell>
            <TableCell className={styles.stickyHeader}>Email</TableCell>
            <TableCell className={styles.stickyHeader}>Phone Number</TableCell>
            <TableCell className={styles.stickyHeader}>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 ? (
            data.map((row, index) => (
              <React.Fragment key={row.id}>
                <TableRow
                  hover={!expandedRows.includes(row.id)}
                  className={`${expandedRows.includes(row.id) ? styles.expandedRow : ""} customTableRow`}
                  style={{ cursor: expandedRows.includes(row.id) ? "default" : "pointer" }}
                >
                  <TableCell className="customTableCell" style={{border:"none", cursor:"text"}}>{row.name || "-"}</TableCell>
                  <TableCell className="customTableCell" style={{border:"none"}}>
                    <a href={`mailto:${row.email}`}>{row.email || "-"}</a>
                  </TableCell>
                  <TableCell className="customTableCell" style={{border:"none", cursor:"text" }}>{row.phone_number || "-"}</TableCell>
                  <TableCell
                    style={{ color: "#2196F3", border: "none", display: "flex", alignItems: "center" }}
                    className="customTableCell"
                  >
                  <Typography
                      onClick={() => !expandedRows.includes(row.id) && handleRowClick(row.id)}
                      style={{
                        color: expandedRows.includes(row.id) ? "#9e9e9e" : "#2196F3",
                        cursor: expandedRows.includes(row.id) ? "default" : "pointer",
                        marginRight: "1rem" // Minimize space between the elements
                      }}
                    >
                      View
                    </Typography>
                    <Typography
                      onClick={() => handleOpenUpdateIRView(row)}
                      style={{
                        color:  "#2196F3",
                        cursor:  "pointer",
                      }}
                    >
                      Edit
                    </Typography>
                  </TableCell>
                </TableRow>
                {expandedRows.includes(row.id) && (
                  <TableRowDetails row={row} index={index} handleRowClick={handleRowClick} />
                )}
              </React.Fragment>
            ))
          ) : (
            !loading && <TableCell style={{border:0,color:'#121926'}} colSpan={8}>Data not Found</TableCell>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  fetchIRDetailsRequest,
  fetchCompanyListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(IRTable);
