import {
  UPDATE_ANY_STATE,
  FETCH_SALES_DASHBOARD_SUCCESS,
  FETCH_SALES_DASHBOARD_FAILURE,
  FETCH_SALES_DASHBOARD,
  UPDATE_SALES_DASHBOARD_VIEW,
} from "../actions/fetchSalesDashboardActions";

export const initialState = {
  loading: false,
  error: null,
  sales_dashboard: [],
  view: "",
  report: "",
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const salesDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ANY_STATE:
      return Object.assign({}, state, action.payload);
    case FETCH_SALES_DASHBOARD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SALES_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sales_dashboard: action.payload.data,
      };
    case FETCH_SALES_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_SALES_DASHBOARD_VIEW:
      return {
        ...state,
        view: action.payload.view.view,
        report: action.payload.view.report,
      };
    default:
      return state;
  }
};

export default salesDashboardReducer;
