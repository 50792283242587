import {
  FETCH_DROP_DOWN_COMPANIES_SUCCESS,
  FETCH_DROP_DOWN_COMPANIES_FAILURE,
  FETCH_DROP_DOWN_COMPANIES_REQUEST,
  FETCH_DROP_DOWN_COMPANIES_RESET,
} from "store/actions/drop-down-companies-action";

const initialState = {
  data: [],
  loading: false,
  error: null,
  total_page: 0,
};

const dropDownCompaniesReducer = (state = initialState, action) => {
   
  switch (action.type) {
    case FETCH_DROP_DOWN_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DROP_DOWN_COMPANIES_RESET:
      return {
        ...state,
        data: [],
        total_page: 0,
      };
    case FETCH_DROP_DOWN_COMPANIES_SUCCESS:
        if(action.payload.data.page===1){
            return {
                ...state,
                loading: false,
                data: action.payload.data.data,
                total_page: action.payload.data.total_pages,
              };
        }
      return {
        ...state,
        loading: false,
        data: [...state.data, ...action.payload.data.data],
        total_page: action.payload.data.total_pages,
      };

    case FETCH_DROP_DOWN_COMPANIES_FAILURE:      
      return {
        ...state,
        loading: false,
        data: [],
        total_page: 1,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default dropDownCompaniesReducer;
