import { call, put, takeEvery } from "redux-saga/effects";
import {
  SELECT_ACCOUNT_ACTION,
} from "store/actions/unifield-account-action";

function* handleSelectAccount(action) {
  // Save the selected account to localStorage
  localStorage.setItem("selectedAccount", JSON.stringify(action.payload));
}

function* watchSelectAccount() {
  yield takeEvery(SELECT_ACCOUNT_ACTION, handleSelectAccount);
}

export default watchSelectAccount;
