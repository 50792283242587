import React from "react";

const Pill = ({ label: pillLabel }) => {
  const getStatusColors = (pillLabel) => {
    const labelColorsMap = {
      Shipped: {
        backgroundColor: "#CBF1DD",
        color: "#005328",
        label: "Shipped",
      },
      Refunded: {
        backgroundColor: "#CBF1DD",
        color: "#005328",
        label: "Refunded",
      },
      Completed: {
        backgroundColor: "#CBF1DD",
        color: "#005328",
        label: "Completed",
      },
      PartiallyShipped: {
        backgroundColor: "#EBD396",
        color: "#483200",
        label: "Partially shipped",
      },
      AwaitingPayment: {
        backgroundColor: "#EBD396",
        color: "#483200",
        label: "Awaiting payment",
      },
      AwaitingShipment: {
        backgroundColor: "#EBD396",
        color: "#483200",
        label: "Awaiting shipment",
      },
      AwaitingFulfillment: {
        backgroundColor: "#EBD396",
        color: "#483200",
        label: "Awaiting fulfillment",
      },
      PartiallyRefunded: {
        backgroundColor: "#EBD396",
        color: "#483200",
        label: "Partially refunded",
      },
      Cancelled: {
        backgroundColor: "#FBD4D0",
        color: "#8A0E00",
        label: "Cancelled",
      },
      Declined: {
        backgroundColor: "#FBD4D0",
        color: "#8A0E00",
        label: "Declined",
      },
      Incomplete: {
        backgroundColor: "#FBD4D0",
        color: "#8A0E00",
        label: "Incomplete",
      },  Declined: {
        backgroundColor: "#FBD4D0",
        color: "#8A0E00",
        label: "Declined",
      },
      Disputed: {
        backgroundColor: "#FBD4D0",
        color: "#8A0E00",
        label: "Disputed",
      },
      ManualVerificationRequired: {
        backgroundColor: "#E3E8EF",
        color: "#121926",
        label: "Manual verification required",
      },
      BoomiProcessing: {
        backgroundColor: "#E3E8EF",
        color: "#121926",
        label: "Boomi processing",
      },
      "AC":{
        backgroundColor: "#CBF1DD",
        color: "#005328",
        label: "Item accepted and shipped",

      },
      "IA":{
        backgroundColor: "#CBF1DD",
        color: "#005328",
        label: "Item accepted",

      },
      "DR":{
        backgroundColor: "#EBD396",
        color: "#483200",
        label: "Item accepted – Date rescheduled",

      },
      "IB ": {
        backgroundColor: "#FBD4D0",
        color: "#8A0E00",
        label: "Item backordered ",
      },
      "IR": {
        backgroundColor: "#FBD4D0",
        color: "#8A0E00",
        label: "Item rejected",
      },
    };
    const defaultColors = {
      backgroundColor: "#EBD396",
      color: "#483200",
      label: pillLabel,
    };
    return labelColorsMap[pillLabel] || defaultColors;
  };

  const { backgroundColor, color, label } = getStatusColors(pillLabel);

  const pillStyle = {
    padding: "2px 10px",
    borderRadius: 100,
    color: color,
    display: "inline-block",
    fontSize: "10px",
    fontWeight: 500,
    whiteSpace: "nowrap",
    backgroundColor: backgroundColor,
  };

  if( pillLabel?.toString().length > 0){
  return <div style={pillStyle}>{label}</div>;
  }  
};

export default Pill;
