// sagas.js
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  FETCH_PRICE_LIST_REQUEST,
  fetchPriceListSuccess,
  fetchPriceListFailure,
} from '../actions/fetchPriceListAction';
import { getRequest } from 'api/apiCall';
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

// API URL
const apiUrl = '/data_syncs/products/get_price_lists/';

// API Request Function
function* fetchPriceListAPI() {
  try {
    const response = yield call(getRequest, apiUrl);
    if (response) {
      return response.data;
    } else {
      throw new Error("Failed to fetch price List: " + response);
    }
  } catch (error) {
    captureCustomSentryEvent("fetchPriceListAPI", error);
    throw error;
  }
}

// Worker Saga
function* fetchPriceList() {
  try {
    const response = yield call(fetchPriceListAPI);
    yield put(fetchPriceListSuccess(response));
  } catch (error) {
    yield put(fetchPriceListFailure(error));
  }
}

// Watcher Saga
function* watchFetchPriceList() {
  yield takeLatest(FETCH_PRICE_LIST_REQUEST, fetchPriceList);
}

export default watchFetchPriceList;
