import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { IconSchool, IconBadgeAd, IconLogout, IconMessages, IconUser, IconNews } from "@tabler/icons";

import { useState } from "react";
import { useRef } from "react";
import { clearAmpSession } from "utils/amplitude";
import { stopScraping } from "utils/scraper-api";

const getInitials = (name) => {
  if (!name) return null;

  const nameParts = name.trim().split(" ");
  return nameParts.length > 1
    ? `${nameParts[0].charAt(0)}${nameParts[1].charAt(0)}`.toUpperCase()
    : nameParts[0].charAt(0).toUpperCase();
};

const profileItemsData = [
  {
    id: 1,
    label: "Product FAQs",
    icon: <IconMessages stroke={1.5} size="1.25rem" />,
    actionType: "link",
    action: "https://bttn.atlassian.net/wiki/spaces/SD/pages/296222725/FAQs+for+Team+Operations+Sales",
  },
  {
    id: 2,
    label: "Marketing Collateral",
    icon: <IconNews stroke={1.5} size="1.25rem" />,
    actionType: "link",
    action:
      "https://drive.google.com/drive/folders/1P349VWDq7B4By87xvBGnwk4q0nKJmPNX?usp=sharing",
  },
  {
    id: 3,
    label: "Video Tutorials",
    icon: <IconSchool stroke={1.5} size="1.25rem" />,
    actionType: "link",
    action:
      "https://drive.google.com/drive/folders/1Xilyr_GD6Zi1LIPI6hFfstsTlYa-5Q6w?usp=drive_link",
  },
  {
    id: 4,
    label: "Logout",
    icon: <IconLogout stroke={1.5} size="1.25rem" />,
    actionType: "logout",
  },
 
];
const Profile = ({ name, account }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const initials = getInitials(name);

  return (
    <>
      <Avatar
        sx={{
          cursor: "pointer",
          bgcolor: "#697586",
          color: "#fff",
          fontSize: "14px",
        }}
        onClick={handleToggle}
        ref={anchorRef}
      >
        {initials ? initials : <IconUser />}
      </Avatar>
      <Popover
        open={open}
        onClose={handleToggle}
        placement="bottom-end"
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ProfilePopOverContent account={account} />
      </Popover>
    </>
  );
};

const ProfilePopOverContent = ({ account }) => {
  const theme = useTheme();
  const handleAction = (item) => {
    if (item.actionType === "link") {
      window.open(item.action, "_blank");
    } else if (item.actionType === "logout") {
      stopScraping(
        account.id,
        () => {},
        (response) => {},
        (error) => {}
      );
      localStorage.clear();
      clearAmpSession();
      window.location.href = "/";
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <nav aria-label="main mailbox folders">
        <List>
          {profileItemsData.map((item) => (
            <div key={item.id}>
               {item.id === profileItemsData.length && (
                <Divider component="li" />
              )}  
              <ListItem disablePadding>
                <ListItemButton  sx={{
                  "&.MuiButtonBase-root":{
                    px:"10px",
                    py:"5px"
                  }
                }} onClick={() => handleAction(item)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{
                        ...theme.typography.menuCaption,
                        my: "0px",
                        fontWeight: 400,
                        fontSize: "13px",
                      }}
                    >
                      {item.label}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </div>
          ))}
        </List>
      </nav>
    </Box>
  );
};

export default Profile;
