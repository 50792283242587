
export const FETCH_ORDER_LIST_REQUEST = 'FETCH_ORDER_LIST_REQUEST';
export const FETCH_ORDER_LIST_SUCCESS = 'FETCH_ORDER_LIST_SUCCESS';
export const FETCH_ORDER_LIST_FAILURE = 'FETCH_ORDER_LIST_FAILURE';



export const fetchOrderListRequest = (url) => ({
    type: FETCH_ORDER_LIST_REQUEST,
    payload:{url}
  });
  
  export const fetchOrderListSuccess = (data) => ({
    type: FETCH_ORDER_LIST_SUCCESS,
    payload: { data },
  });
  
  export const fetchOrderListFailure = (error) => ({
    type: FETCH_ORDER_LIST_FAILURE,
    payload: { error },
  });
  