import { takeLatest, call, put } from "redux-saga/effects";
import {
  FETCH_ACTION_L1CATEGORY_REQUEST,
  fetchL1categorySuccess,
  fetchL1categoryFailure,
} from "store/actions/fetchCategoryAction";
import { getRequest } from "api/apiCall";
function* fetchL1categorySaga(action) {
  try {
    const { params:{url,page} } = action.payload;
    const apiUrl =  url.length ? `/data_syncs/catalog/categories/?page_size=20&page=${page}&category=${url}` :`/data_syncs/catalog/categories/?page_size=20&page=${page}`;
    const response = yield call(getRequest, apiUrl);
    yield put(fetchL1categorySuccess({response,page:page}));
  } catch (error) {
    //   captureCustomSentryEvent("fetchShoppingList", error);
    yield put(fetchL1categoryFailure(error));
  }
}

function* l1CategorySaga() {
  yield takeLatest(FETCH_ACTION_L1CATEGORY_REQUEST, fetchL1categorySaga);
}

export default l1CategorySaga;
