import { call, put, takeLatest, all, delay } from 'redux-saga/effects';
import { postRequest } from 'api/apiCall';
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";
import { fetchDocumentsRequest, resetAccountRecordsState } from '../actions/accountRecordsAction'; 
import {
  setFiles,
  setLoading,
  setUploadSuccess,
  setUploadProgress,
  setUploadStatus,
  setAlertErrorMessage,
  setUploadPopupOpen
} from '../actions/UploadRecordsAction';;


function* handleFileUpload(action) {
  try {
    yield put(setLoading(true));
    const { selectedAccountID, files } = action.payload;

    // Get presigned URLs for each file
    const response = yield call(postRequest, '/customers/documents/generate-presigned-urls', {
      company_id: selectedAccountID,
      file_names: files.map((file) => file.name),
    });

    // Upload files using presigned URLs
    const uploadPromises = response.upload_urls.map(({ url, file_name }) => {
      const file = files.find((f) => f.name === file_name);

      return function* () {
        // Set initial progress to 50
        yield put(setUploadProgress({ [file_name]: 50 }));

        try {
          const response = yield call(fetch, url, {
            method: 'PUT',
            body: new File([file], file_name),
          });

          if (response.ok) {
            yield put(setUploadProgress({ [file_name]: 100 }));
            yield put(setUploadStatus({ [file_name]: 'success' }));
          } else {
            yield put(setUploadStatus({ [file_name]: 'error' }));
            throw new Error('File upload failed');
          }
        } catch (error) {
          captureCustomSentryEvent('handleFileUpload', error);
          yield put(setUploadStatus({ [file_name]: 'error' }));
          throw error;
        }
      };
    });

    yield all(uploadPromises.map((promise) => call(promise)));

    // Refresh account list and update state    
    yield put(setLoading(false));
    yield put(setFiles([]));
    yield put(setUploadSuccess(true));
    yield delay(3000);
    yield put(resetAccountRecordsState());
    yield put(fetchDocumentsRequest(selectedAccountID, 1));
    yield put(setUploadPopupOpen(false));
    yield put(setUploadSuccess(false));    
  } catch (error) {
    captureCustomSentryEvent('handleFileUpload', error);
    yield put(setLoading(false));
    yield put(setAlertErrorMessage('Failed to upload files. Please try again.'));
  }
}

function* watchFileUpload() {
  yield takeLatest('FILE_UPLOAD_REQUESTED', handleFileUpload);
}

export default function* uploadRecordsSaga() {
  yield all([watchFileUpload()]);
}