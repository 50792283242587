// sagas/notificationSaga.js

import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_NOTIFICATION_REQUEST,
  fetchNotificationSuccess,
  fetchNotificationFailure,
} from "../actions/fetchNotificationActions";
import { getRequest } from "api/apiCall";
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

function* fetchNotificationSaga(action) {
  try {
    const data = yield call(getRequest, `/customers/notifications/?include_notifications=${action.payload.params}`);

    yield put(fetchNotificationSuccess(data.data));
  } catch (error) {
    captureCustomSentryEvent("fetchNotificationSaga", error);
    yield put(fetchNotificationFailure(error.message));
  }
}

function* notificationSaga() {
  yield takeEvery(FETCH_NOTIFICATION_REQUEST, fetchNotificationSaga);
}

export default notificationSaga;
