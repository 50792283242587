import {
    UPDATE_ANY_STATE,
    FETCH_SALES_REP_RECORDS_SUCCESS,
    FETCH_SALES_REP_RECORDS_FAILURE,
    FETCH_SALES_REP_RECORDS,
    UPDATE_SALES_REP_OPTION,
    UPDATE_CUSTOMER_OPTION,
    FETCH_DAILY_SALES_SUMMARY_SUCCESS,
    FETCH_DAILY_SALES_SUMMARY_FAILURE,
    UPDATE_DAILY_DATE_OPTION,
    FETCH_SALES_REP_CUSTOMERS_RECORDS,
    FETCH_SALES_REP_CUSTOMERS_RECORDS_SUCCESS,
    FETCH_SALES_REP_CUSTOMERS_RECORDS_FAILURE,
    FETCH_DAILY_SALES_DETAILS_SUCCESS,
    FETCH_DAILY_SALES_DETAILS_FAILURE,
    RESET_SALESREP_LOADING,
    UPDATE_SUMMARY_VIEW
    
} from '../actions/fetchSalesRepRecordsActions';

export const initialState = {
  loading: false,
  error: null,
  sales_rep: [],
  sales_rep_customers: [],
  salesRepOption: '',
  salesRepCustomerOption: '',
  dailySalesSummary: [],
  dailySalesDetails: [],
  sellingDays: 22,
  monthlyGoalGrossSales: 100000,
  monthlyGoalGP: 25000,
  DateOption: new Date().toISOString().split('T')[0],
  totalSalePriceSum: 0,
  grossProfitSum: 0,
  totalDailySalePriceSum: 0,
  dailyGrossProfitSum: 0,
  view: '',
  report: ''
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const salesrepReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ANY_STATE:
      return Object.assign({}, state, action.payload)
    case FETCH_SALES_REP_RECORDS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SALES_REP_RECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sales_rep: action.payload.data
      };
    case FETCH_SALES_REP_RECORDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_SALES_REP_CUSTOMERS_RECORDS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SALES_REP_CUSTOMERS_RECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sales_rep_customers: action.payload.data
      };
    case FETCH_SALES_REP_CUSTOMERS_RECORDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_SALES_REP_OPTION:      
      return {
        ...state,
        loading: true,
        error: null,
        salesRepOption: action.payload.data.salesRepOption
      };
    case UPDATE_CUSTOMER_OPTION:      
      return {
        ...state,
        loading: true,
        error: null,
        salesRepCustomerOption: action.payload.data.salesRepCustomerOption
      };
    case FETCH_DAILY_SALES_SUMMARY_SUCCESS:      
      return {
        ...state,
        loading: false,
        error: null,
        dailySalesSummary: action.payload.data.data,
        totalSalePriceSum: action.payload.data.totalSalePriceSum,
        grossProfitSum: action.payload.data.grossProfitSum
      };
    case FETCH_DAILY_SALES_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_DAILY_SALES_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        dailySalesDetails: action.payload.data.data,
        totalDailySalePriceSum: action.payload.data.totalDailySalePriceSum,
        dailyGrossProfitSum: action.payload.data.dailyGrossProfitSum
      };
    case FETCH_DAILY_SALES_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_DAILY_DATE_OPTION:
      return {
        ...state,
        loading: false,
        DateOption: action.payload.date.DateOption
      };
    case UPDATE_SUMMARY_VIEW:
      return {
        ...state,
        view: action.payload.view.view,
        report: action.payload.view.report
      };
    case RESET_SALESREP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default salesrepReducer;
