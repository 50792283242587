export const UPDATE_ANY_STATE = "UPDATE_ANY_STATE";
export const FETCH_SALES_DASHBOARD = "FETCH_SALES_DASHBOARD";
export const FETCH_SALES_DASHBOARD_SUCCESS = "FETCH_SALES_DASHBOARD_SUCCESS";
export const FETCH_SALES_DASHBOARD_FAILURE = "FETCH_SALES_DASHBOARD_FAILURE";
export const UPDATE_SALES_DASHBOARD_VIEW = "UPDATE_SALES_DASHBOARD_VIEW";
export const RESET_SALESREP_LOADING = "RESET_SALESREP_LOADING";

export const resetSalesRepLoading = () => ({
  type: RESET_SALESREP_LOADING,
});
export const updateAnyState = (data) => ({
  type: UPDATE_ANY_STATE,
  payload: data,
});

export const fetchSalesDashboard = (resourceId, isIRDashboard) => {
  return ({
    type: FETCH_SALES_DASHBOARD,
    payload: { resourceId, isIRDashboard }
  })
};

export const fetchSalesDashboardSuccess = (data) => {
  return {
    type: FETCH_SALES_DASHBOARD_SUCCESS,
    payload: { data },
  };
};

export const fetchSalesDashboardFailure = (error) => {
  return {
    type: FETCH_SALES_DASHBOARD_FAILURE,
    payload: { error },
  };
};

export const updateSalesDashboardView = (view) => {
  return ({
    type: UPDATE_SALES_DASHBOARD_VIEW,
    payload: { view },
  })};
