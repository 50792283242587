import { takeLatest, call, put } from "redux-saga/effects";
import {
  FETCH_SHOPPING_CART_REQUEST,
  fetchShoppingCartSuccess,
  fetchShoppingCartFailure,
} from "../actions/shoppingCartActions";
import { getRequest } from "api/apiCall";
import captureCustomSentryEvent from "Sentry/captureCustomSentryEvent";

function* fetchShoppingCart(action) {
  try {
    const { companyId } = action.payload;
    const url = `/shopping-cart/shopping-cart?company_id=${companyId}`;

    const response = yield call(getRequest, url);

    yield put(fetchShoppingCartSuccess(response));
  } catch (error) {
    captureCustomSentryEvent("fetchShoppingCart", error);
    yield put(fetchShoppingCartFailure(error));
  }
}

function* shoppingCartSaga() {
  yield takeLatest(FETCH_SHOPPING_CART_REQUEST, fetchShoppingCart);
}

export default shoppingCartSaga;