import {
    FETCH_SHOPPING_CART_ITEMS_REQUEST,
    FETCH_SHOPPING_CART_ITEMS_SUCCESS,
    FETCH_SHOPPING_CART_ITEMS_FAILURE,
  } from "../actions/fetchShoppingCartItemsAction";
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
    shopping_cart_id:"",
    total_non_stocked_count:0,
    total_page:0,
    total_records:0,
    blended_margins: 0,
    gross_profit: 0,
    total_order_value: 0,
  };
  
  const shoppingCartItemsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SHOPPING_CART_ITEMS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_SHOPPING_CART_ITEMS_SUCCESS:
       
        if(action.payload.data.page_number ===1){
           
          return {
            ...state,
            data: action.payload.data.data.shopping_cart_items,
            total_non_stocked_count:action.payload.data.total_non_stocked_count,
            total_page: action.payload.data.total_pages,
            blended_margins: action.payload.data.blended_margins,
            gross_profit: action.payload.data.gross_profit,
            total_order_value: action.payload.data.total_order_value,
            loading: false,
            shopping_cart_id:action.payload.data.data.shopping_cart_id,
            total_records:action.payload.data.total_records,
            error: null,
          };
          
        }
  
  
        return {
          ...state,
          data: [...state.data,...action.payload.data.data.shopping_cart_items],
          loading: false,
          error: null,
        };
      case FETCH_SHOPPING_CART_ITEMS_FAILURE:
        return {
          ...state,
          data: [],
          blended_margins: 0,
          gross_profit: 0,
          total_order_value: 0,
          loading: false,
          error: action.payload.error,
        };
      default:
        return state;
    }
  };
  
  export default shoppingCartItemsReducer;
  