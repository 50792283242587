// Import action types from your actions file
import {
  FETCH_ORDER_LIST_REQUEST,
  FETCH_ORDER_LIST_SUCCESS,
  FETCH_ORDER_LIST_FAILURE,
} from "../actions/fetchOrderListAction";

// Initial state for the order list reducer
const initialState = {
  data: [],
  loading: false,
  error: null,
  hasNextPage: false,
};

// Reducer function for order list
const orderListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ORDER_LIST_SUCCESS:
      if (action.payload.data["previous"] == null) {
        return {
          ...state,
          data: action.payload.data.results,
          hasNextPage: action.payload.data.next,

          loading: false,
          error: null,
        };
      }
      return {
        ...state,
        data: [...state.data, ...action.payload.data.results],
        hasNextPage: action.payload.data.next !== null,

        loading: false,
        error: null,
      };
    case FETCH_ORDER_LIST_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default orderListReducer;
